<template>
  <div class="access-restriction-wrapper">
    <spinner v-if="isLoading" :no-message="true"></spinner>
    <div v-else class="access-restriction">
      <div class="activation">
        <div class="activation__switch" :data-cy="`access-restriction-switch`">
          <label class="switch">
            <input
              :id="`activation-switch-${apiName}`"
              type="checkbox"
              :checked="isGlobalChecked()"
              @change="onGlobalClick"
              :data-cy="`access-restriction-checkbox`"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div class="activation__text">
          <label :for="`activation-switch-${apiName}`">API {{ apiLabel }}</label>
        </div>
      </div>
      <div
        class="activation sub"
        v-for="(endpoint, index) in getEndpoints[apiName]"
        :key="endpoint"
      >
        <div class="activation__switch" :data-cy="`access-restriction-endpoint-switch`">
          <label class="switch">
            <input
              :id="`activation-switch-endpoint-${apiName}-${index}`"
              type="checkbox"
              :checked="isEndpointChecked(endpoint)"
              @change="onEndpointClick(endpoint)"
              :data-cy="`access-restriction-endpoint-checkbox`"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div class="activation__text">
          <label :for="`activation-switch-endpoint-${apiName}-${index}`">
            {{ endpoint }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "ApiKeyAccessRestrictions",
  computed: {
    ...mapGetters("swagger", ["getEndpoints"]),
    ...mapGetters("apiKeys", ["listBlockedEndpoints"]),
  },
  props: {
    apiLabel: { type: String, required: true },
    apiName: { type: String, required: true },
    swaggerUrl: { type: String, required: true },
  },
  data() {
    return {
      isLoading: true,
    }
  },
  methods: {
    async onGlobalClick() {
      if (this.isGlobalChecked()) {
        let newEndpointList = [...this.getEndpoints[this.apiName]]
        await this.$store.dispatch("apiKeys/setApiKeyEndpointsInScope", {
          apiName: this.apiName,
          endpoints: newEndpointList,
        })
      } else {
        await this.$store.dispatch("apiKeys/setApiKeyEndpointsInScope", {
          apiName: this.apiName,
          endpoints: [],
        })
      }
      this.$forceUpdate()
    },
    isGlobalChecked() {
      if (this.listBlockedEndpoints[this.apiName]) {
        return (
          this.listBlockedEndpoints[this.apiName].length !==
          this.getEndpoints[this.apiName].length
        )
      }
      return true
    },
    async onEndpointClick(endpoint) {
      if (this.listBlockedEndpoints[this.apiName]) {
        if (this.listBlockedEndpoints[this.apiName].includes(endpoint)) {
          let newEndpointList = this.listBlockedEndpoints[this.apiName]
          const index = newEndpointList.indexOf(endpoint)
          newEndpointList.splice(index, 1)
          await this.$store.dispatch("apiKeys/setApiKeyEndpointsInScope", {
            apiName: this.apiName,
            endpoints: newEndpointList,
          })
        } else {
          const newEndpointList = [...this.listBlockedEndpoints[this.apiName], endpoint]
          await this.$store.dispatch("apiKeys/setApiKeyEndpointsInScope", {
            apiName: this.apiName,
            endpoints: newEndpointList,
          })
        }
      } else {
        const newEndpointList = [endpoint]
        await this.$store.dispatch("apiKeys/setApiKeyEndpointsInScope", {
          apiName: this.apiName,
          endpoints: newEndpointList,
        })
      }
      this.$forceUpdate()
    },
    isEndpointChecked(endpoint) {
      if (this.listBlockedEndpoints[this.apiName]) {
        if (this.listBlockedEndpoints[this.apiName].length === 0) {
          return true
        }
        return !this.listBlockedEndpoints[this.apiName].includes(endpoint)
      }
      return true
    },
  },
  async created() {
    this.isLoading = true
    await this.$store.dispatch("swagger/getEndpoints", {
      apiName: this.apiName,
      swaggerUrl: this.swaggerUrl,
    })
    this.isLoading = false
  },
}
</script>

<style scoped lang="scss">
.access-restriction-wrapper {
  width: 50%;
}

.sub {
  margin-left: 3rem;
  margin-top: 1rem;
}

.activation {
  display: flex;
  align-items: center;
  width: 100%;

  &__text {
    justify-self: end;
    font-family: "Effra Regular", sans-serif;
    font-size: 0.938rem;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  &__switch {
    justify-self: end;
    margin-right: 1rem;
    overflow: hidden;
  }

  label {
    cursor: pointer;
  }
}
</style>
