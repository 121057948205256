import Vue from "vue"
import VueRouter from "vue-router"
import CypressAuth from "@/views/CypressAuth"
import Home from "@/views/Home"
import Layout from "@/layouts/Layout"
import NoLayout from "@/layouts/LayoutAuth"
import guards from "./guards"
import Page403 from "@/views/errors/Page403"
import Page404 from "@/views/errors/Page404"
import Page500 from "@/views/errors/Page500"
import GroupService from "@/services/business/GroupService"
import ApisLayout from "@/layouts/ApisLayout"
import ApiKeyResources from "@/components/apiKeys/resources/ApiKeyResources"
import ApiKeys from "@/components/apiKeys/home/ApiKeys"
import ApiKeysLayout from "@/layouts/ApiKeysLayout"
import ApiDoc from "@/components/api/ApiDoc"
import ApiKeySecurity from "@/components/apiKeys/security/ApiKeySecurity"
import ApiKeyAccess from "@/components/apiKeys/access/ApiKeyAccess.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/:lang",
    name: "lang",
    beforeEnter: guards.beforeEnter,
    redirect: { name: "privateZone" },
    component: {
      render(c) {
        return c("router-view")
      },
    },
    children: [
      {
        path: "",
        name: "privateZone",
        beforeEnter: guards.requireAuth,
        redirect: { name: "home" },
        component: Layout,
        meta: { breadcrumb: "breadcrumb.home" },
        children: [
          {
            path: "",
            name: "home",
            component: Home,
            meta: {
              breadcrumb: "breadcrumb.home",
              maxGroupId: GroupService.BENEFICIARY,
              fullLayout: true,
            },
          },
          {
            path: "apis",
            name: "discoverApi",
            beforeEnter: guards.requireAcceptedCookies,
            redirect: { name: "home" },
            meta: {
              breadcrumb: "breadcrumb.discoverApi",
              maxGroupId: GroupService.BENEFICIARY,
            },
            component: ApisLayout,
            children: [
              {
                path: "apisales",
                name: "apisales",
                component: ApiDoc,
                meta: {
                  breadcrumb: "breadcrumb.sales",
                  maxGroupId: GroupService.BENEFICIARY,
                  layoutBackgroundColor: "#f5f5f6",
                  urlSwagger: "VUE_APP_API_SALES",
                  apiId: "apisales",
                },
              },
              {
                path: "apimycaarea",
                name: "apimycaarea",
                component: ApiDoc,
                meta: {
                  breadcrumb: "breadcrumb.mycaarea",
                  maxGroupId: GroupService.BENEFICIARY,
                  layoutBackgroundColor: "#f5f5f6",
                  urlSwagger: "VUE_APP_API_MY_CAAREA",
                  apiId: "apimycaarea",
                },
              },
              {
                path: "apiclaims",
                name: "apiclaims",
                component: ApiDoc,
                meta: {
                  breadcrumb: "breadcrumb.claims",
                  maxGroupId: GroupService.BENEFICIARY,
                  layoutBackgroundColor: "#f5f5f6",
                  urlSwagger: "VUE_APP_API_CLAIMS",
                  apiId: "apiclaims",
                },
              },
              {
                path: "apimonitoring",
                name: "apimonitoring",
                component: ApiDoc,
                meta: {
                  breadcrumb: "breadcrumb.monitoring",
                  maxGroupId: GroupService.BENEFICIARY,
                  layoutBackgroundColor: "#f5f5f6",
                  urlSwagger: "VUE_APP_API_MONITORING",
                  apiId: "apimonitoring",
                },
              },
              {
                path: "apikeys",
                name: "api-keys",
                redirect: { name: "api-keys-list" },
                meta: {
                  breadcrumb: "breadcrumb.api_keys",
                  maxGroupId: GroupService.DISTRIBUTOR_ADMIN,
                  layoutBackgroundColor: "#f5f5f6",
                },
                component: ApiKeysLayout,
                children: [
                  {
                    path: "",
                    name: "api-keys-list",
                    component: ApiKeys,
                    meta: {
                      maxGroupId: GroupService.DISTRIBUTOR_ADMIN,
                      layoutBackgroundColor: "#f5f5f6",
                    },
                  },
                  {
                    path: ":id/security",
                    name: "api-key-security",
                    props: true,
                    meta: {
                      breadcrumb: "breadcrumb.security",
                      maxGroupId: GroupService.DISTRIBUTOR_ADMIN,
                      layoutBackgroundColor: "#f5f5f6",
                    },
                    component: ApiKeySecurity,
                  },
                  {
                    path: ":id/resources",
                    name: "api-key-resources",
                    props: true,
                    meta: {
                      breadcrumb: "breadcrumb.resources",
                      maxGroupId: GroupService.DISTRIBUTOR_ADMIN,
                      layoutBackgroundColor: "#f5f5f6",
                    },
                    component: ApiKeyResources,
                  },
                  {
                    path: ":id/access",
                    name: "api-key-access",
                    props: true,
                    meta: {
                      breadcrumb: "breadcrumb.access",
                      maxGroupId: GroupService.DISTRIBUTOR_ADMIN,
                      layoutBackgroundColor: "#f5f5f6",
                    },
                    component: ApiKeyAccess,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "",
        redirect: { name: "home" },
        name: "publicZone",
        component: NoLayout,
        children: [
          {
            beforeEnter: guards.logout,
            path: "logout",
            name: "logout",
          },
        ],
      },
      {
        path: "error",
        redirect: { name: "404" },
        name: "error",
        component: NoLayout,
        children: [
          {
            path: "403",
            name: "403",
            component: Page403,
          },
          {
            path: "404/:message?",
            name: "404",
            component: Page404,
            props: true,
          },
          {
            path: "500",
            name: "500",
            component: Page500,
          },
        ],
      },
      {
        path: "*",
        beforeEnter: guards.beforeEnter,
      },
    ],
  },
  {
    path: "*",
    beforeEnter: guards.beforeEnter,
  },
]

if (window.Cypress) {
  // add route for testing purpose
  routes[0].children[0].children.push({
    path: "cypress",
    name: "cypress",
    component: CypressAuth,
  })
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
