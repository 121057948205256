import Vue from "vue"
import Vuex from "vuex"
import auth from "@/store/auth"
import config from "@/store/config"
import partner from "@/store/partner"
import apiKeys from "@/store/api_keys"
import partnerKey from "@/store/partner_key"
import gui from "@/store/gui"
import swagger from "@/store/swagger"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    config,
    partner,
    apiKeys,
    partnerKey,
    gui,
    swagger,
  },
})
