<template>
  <ModalBase :show="show" :modal-name="modalName">
    <template #header>
      <div class="modal-warning__header">
        <h3 class="modal-warning__header__title" data-cy="modal-warning-title">
          {{ title }}
        </h3>
        <button
          type="button"
          class="modal-warning__header__close icon-cross"
          @click="onClose"
        ></button>
      </div>
    </template>
    <template #content>
      <div class="modal-warning__content">
        <p class="font-size-20" data-cy="modal-warning-reason">{{ reason }}</p>
      </div>
    </template>
    <template #footer>
      <div class="modal-warning__footer">
        <button
          class="btn btn-primary"
          data-cy="modal-warning-button"
          @click.prevent="onClose"
        >
          {{ $t("button.ok") }}
        </button>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import ModalBase from "@/components/modal/ModalBase"

export default {
  name: "ModalWarning",
  components: { ModalBase },
  props: {
    modalName: { type: String },
    title: { type: String },
    reason: { type: String },
    show: { type: Boolean, default: true },
  },
  methods: {
    onClose() {
      this.$emit("close")
    },
  },
}
</script>

<style scoped lang="scss">
.modal-warning {
  &__header {
    display: flex;
    justify-content: space-between;

    &__title {
      align-self: center;
      flex: 1;
    }

    &__close {
      color: $bleuc400;
      transition: all 0.3s;
      cursor: pointer;
      font-size: 1.5rem;
      position: relative;
      border: none;
      background: transparent;
      align-self: center;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__footer {
    display: flex;
    justify-content: center;
  }
}
</style>
