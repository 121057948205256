<template>
  <ErrorPageContainer
    :image="image"
    number="404"
    :message="message"
  ></ErrorPageContainer>
</template>
<script>
import ErrorPageContainer from "../../components/errors/ErrorPageContainer"

export default {
  name: "Page404",
  components: { ErrorPageContainer },
  props: { message: { type: String, default: null } },
  data() {
    return {
      image: {
        src: "https://res.cloudinary.com/caarea/image/upload/v1617955698/cs/assets/404_qktbda.png",
        alt: "404 not found",
      },
    }
  },
}
</script>
