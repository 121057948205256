export default {
  fetchLoggedUser: "/users/me",
  userAcceptCookies: "/users/{id}/cookies/",
  swaggerDoc: "/api/public/v1.1/schema/",
  partners: "partners/",
  partnerKey: "partner-key/detail/",
  apiKey: "/api-portal-api-key/",
  apiKeyById: "/api-portal-api-key/{id}/",
  programRestriction: "/api-portal-api-key/{id}/program-restriction/",
  userPartner: "users/{id}/partner",
}
