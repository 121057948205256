<template>
  <div>
    <spinner v-if="isLoading" :no-message="true"></spinner>
    <div v-else>
      <div class="api-keys-security" data-cy="api_keys_security">
        <ApiKeySettingsHeader
          :api-key-id="id"
          :section-title="$t('apiKeysSecurity.title')"
          :description="$t('apiKeysSecurity.description')"
          data-cy-prefix="program-restriction"
        ></ApiKeySettingsHeader>
        <div class="api-keys-security__restriction">
          <ApiKeySecurityRestrictions
            :api-key-id="id"
            restriction-type="ips"
          ></ApiKeySecurityRestrictions>
          <ApiKeySecurityRestrictions
            :api-key-id="id"
            restriction-type="urls"
          ></ApiKeySecurityRestrictions>
        </div>
        <ActionButton
          :button-text="$t('button.save')"
          :button-class="['apikeys__save_button']"
          @click-action="onSavePermissions"
          button-data-cy="save-button"
        ></ActionButton>
      </div>
    </div>
  </div>
</template>

<script>
import ApiKeySecurityRestrictions from "@/components/apiKeys/security/ApiKeySecurityRestrictions"
import ApiKeySettingsHeader from "@/components/apiKeys/utils/ApiKeySettingsHeader"
import ActionButton from "@/components/button/ActionButton"
import { mapGetters } from "vuex"

export default {
  name: "ApiKeySecurity",
  components: { ApiKeySettingsHeader, ApiKeySecurityRestrictions, ActionButton },
  props: {
    id: { type: [Number, String], required: true },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters("apiKeys", ["getApiKeyScopeUpdateErrors"]),
  },
  methods: {
    async onSavePermissions() {
      await this.$store.dispatch("apiKeys/updateApiKeyPermissions")
      if (this.getApiKeyScopeUpdateErrors === null) {
        this.$store.dispatch("gui/setApiKeyUpdateFeedbackModalInfo", {
          title: this.$t("apiKeysSecurity.feedback_modal.title"),
          message: this.$t("apiKeysSecurity.feedback_modal.message"),
        })
        this.$store.dispatch("gui/showApiKeyUpdateFeedbackModal")
      } else {
        this.$store.dispatch("gui/setApiKeyUpdateWarningModalInfo", {
          title: this.$t("apiKeysSecurity.warning_modal.title"),
          reason: this.getApiKeyScopeUpdateErrors,
        })
        this.$store.dispatch("gui/showApiKeyUpdateWarningModal")
      }
    },
  },
  async created() {
    this.isLoading = true
    await this.$store.dispatch("apiKeys/retrieveApiKey", { id: this.id })
    await this.$store.dispatch("apiKeys/openPermissionsApiKeyView", this.id)
    this.isLoading = false
  },
}
</script>

<style scoped lang="scss">
.api-keys-security {
  &__restriction {
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 1fr 1rem;
    grid-column-gap: 3rem;
    padding-bottom: 24px;
  }
}
</style>
