<template>
  <div class="apikey_list">
    <ApiKeyNameModal
      v-if="apiKeyModalOpen"
      @close="onModalClose"
      @api-key-created="onOpenApiKeyUniqueModal"
      modal-name="api-key-name-modal"
    ></ApiKeyNameModal>
    <ApiKeyUniqueModal
      v-if="apiKeyUniqueModalOpen"
      @close="onCloseApiKeyUniqueModal"
      :api-unique-key="apiUniqueKey"
      modal-name="api-key-unique-modal"
    ></ApiKeyUniqueModal>
    <div class="apikey_list__header">
      <div class="apikey_list__left-insert"></div>
      <div class="apikey_list__right">
        <h4 class="apikey_list__h4">{{ $t("apikeys.my_api_keys") }}</h4>
        <p class="apikey_list__title">
          {{ $t("apikeys.keys_list_title") }}
        </p>
        <p class="apikey_list__alert">{{ $t("apikeys.alert") }}</p>
      </div>
    </div>
    <div class="apikey_list__table">
      <div
        v-if="Object.keys(getApiKeys).length === 0"
        class="apikey-list__no_key"
        data-cy="apikey-list-no-key"
      >
        <h2>{{ $t("apikeys.no_key") }}</h2>
        <ActionButton
          :button-text="$t('apikeys.create_button')"
          @click-action="onAddApiKey"
        ></ActionButton>
      </div>
      <div v-else>
        <ApiKeyDetails
          :key-info="key"
          v-for="key in getApiKeys"
          :key="key.id"
        ></ApiKeyDetails>

        <div class="action-button">
          <ActionButton
            :button-text="$t('apikeys.create_button')"
            @click-action="onAddApiKey"
          ></ActionButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ApiKeyDetails from "@/components/apiKeys/home/ApiKeyDetails"
import ActionButton from "@/components/button/ActionButton"
import ApiKeyNameModal from "@/components/modal/ApiKeyNameModal"
import ApiKeyUniqueModal from "@/components/modal/ApiKeyUniqueModal"

export default {
  components: { ActionButton, ApiKeyDetails, ApiKeyNameModal, ApiKeyUniqueModal },
  name: "ApiKeysList",
  data() {
    return {
      apiKeyModalOpen: false,
      apiKeyUniqueModalOpen: false,
      apiUniqueKey: "",
    }
  },
  computed: {
    ...mapGetters("apiKeys", ["getApiKeys"]),
  },
  methods: {
    onAddApiKey() {
      this.apiKeyModalOpen = true
    },
    onModalClose() {
      this.apiKeyModalOpen = false
    },
    onOpenApiKeyUniqueModal(api_key) {
      this.onModalClose()
      this.apiUniqueKey = api_key
      this.apiKeyUniqueModalOpen = true
    },
    onCloseApiKeyUniqueModal() {
      this.apiUniqueKey = ""
      this.apiKeyUniqueModalOpen = false
    },
  },
}
</script>

<style scoped lang="scss">
.apikey_list {
  margin-top: 20px;
  .apikey_list__header {
    border-bottom: 1px solid #e7eaf0;
    display: grid;
    grid-template-columns: 6px auto;
    .apikey_list__left-insert {
      background: $bleuc400;
    }
    .apikey_list__right {
      padding: 12px 44px 4px 20px;
      background: #fff;
      column-gap: 20px;
      row-gap: 8px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas:
        "h4 alert"
        "title alert";
      h4,
      p {
        margin: 0;
      }
      .apikey_list__h4 {
        grid-area: h4;
      }
      .apikey_list__title {
        grid-area: title;
        font-family: "Effra Light", sans-serif;
        font-size: 11px;
      }
      .apikey_list__alert {
        grid-area: alert;
        align-self: center;
        text-align: right;
        font-family: "Effra Light", sans-serif;
        font-size: 11px;
        color: #ff0c3e;
      }
    }
  }
  .apikey_list__table {
    margin-bottom: 15px;
    .apikey-list__no_key {
      background-color: #fff;
      padding: 52px 0 33px 0;
      text-align: center;
      h2 {
        font-size: 22px;
        letter-spacing: 0.09em;
        margin-bottom: 20px;
      }
    }
  }
  .apikey_list__button {
    margin-bottom: 20px;
  }
  .action-button {
    margin-top: 13px;
  }
}
</style>
