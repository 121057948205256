<template>
  <div class="action">
    <div class="action__circle">
      <h4>{{ verb }}</h4>
    </div>
    <p>{{ description }}</p>
  </div>
</template>

<script>
export default {
  props: {
    verb: { type: String, required: true },
    description: { type: String, required: true },
  },
}
</script>

<style lang="scss" scoped>
.action {
  $circleSize: 65px;
  text-align: center;
  max-width: 190px;

  .action__circle {
    position: relative;
    height: $circleSize;
    width: $circleSize;
    background-color: $caarealightgray;
    margin: auto;
    border-radius: 50%;
    border: 10px solid white;

    h4 {
      position: absolute;
      font-family: "Gilroy SemiBold";
      top: calc(#{$circleSize} / 2 - 11px);
      left: calc(#{$circleSize} / 2);
      margin: 0;
      font-weight: bold;
      text-align: right;
      font-size: 18px;
      white-space: nowrap;
    }
  }

  p {
    margin: 0;
    font-family: "Effra", sans-serif;
    font-weight: lighter;
    font-size: 12px;
    letter-spacing: 1px;
  }
}
.action:not(:last-child) h4:after {
  position: absolute;
  content: "";
  display: inline-block;
  width: 95px;
  border-top: 1px dashed $bleucaarea;
  top: 12px;
  margin-left: 10px;
}
</style>
