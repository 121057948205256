// STATES (snake_case)
import Vue from "vue"
import HttpService, { HttpError } from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"
import i18n from "../i18n"

const state = {
  api_keys_by_id: null,
  create_errors: null,
  api_key_permissions: {
    current_api_key_id: null,
    scope: {
      ips: [],
      urls: [],
      endpoints: {},
    },
    api_key_update_errors: null,
  },
  program_restriction_update_errors: null,
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_API_KEYS_BY_ID(state, apiKeys) {
    state.api_keys_by_id = apiKeys.reduce((apiKeysById, apiKey) => {
      apiKeysById[apiKey.id] = apiKey
      return apiKeysById
    }, {})
  },
  SET_API_KEY(state, apiKey) {
    if (state.api_keys_by_id === null) state.api_keys_by_id = {}
    Vue.set(state.api_keys_by_id, apiKey.id, apiKey)
  },
  SET_API_KEY_CREATE_ERRORS(state, errors) {
    state.create_errors = errors
  },
  SET_CURRENT_API_KEY_ID(state, apiKeyId) {
    state.api_key_permissions.current_api_key_id = apiKeyId
  },
  SET_API_KEY_UPDATE_ERRORS(state, errors) {
    state.api_key_permissions.api_key_update_errors = errors
  },
  SET_API_KEY_IPS_SCOPE(state, ips) {
    state.api_key_permissions.scope.ips = ips
  },
  SET_API_KEY_URLS_SCOPE(state, urls) {
    state.api_key_permissions.scope.urls = urls
  },
  SET_API_KEY_INITIAL_ENDPOINTS_SCOPE(state, endpoints) {
    state.api_key_permissions.scope.endpoints = endpoints
  },
  SET_API_KEY_ENDPOINTS_SCOPE(state, payload) {
    state.api_key_permissions.scope.endpoints[payload.apiName] = payload.endpoints
  },
  RESET_API_KEY_CREATE_ERRORS(state) {
    state.create_errors = null
  },
  RESET_API_KEY_UPDATE_ERRORS(state) {
    state.api_key_permissions.api_key_update_errors = null
  },
  RESET_API_KEY_PERMISSIONS(state) {
    state.api_key_permissions.current_api_key_id = null
    state.api_key_permissions.scope.ips = []
    state.api_key_permissions.scope.urls = []
    state.api_key_permissions.scope.endpoints = {}
    state.api_key_permissions.api_key_update_errors = null
  },
  SET_PROGRAM_RESTRICTION_UPDATE_ERRORS(state, errors) {
    state.program_restriction_update_errors = errors
  },
  RESET_PROGRAM_RESTRICTION_UPDATE_ERRORS(state) {
    state.program_restriction_update_errors = null
  },
}

// ACTIONS (camelCase)
const actions = {
  async retrieveApiKeys({ commit }, partnerId) {
    let query = {}
    if (partnerId) query["partner_id"] = partnerId
    try {
      const apiKeys = await HttpService.get(UrlService.render("apiKey", {}, query))
      commit("SET_API_KEYS_BY_ID", apiKeys)
    } catch (e) {
      console.error("failed: ", e)
      throw e
    }
  },
  async create({ commit, dispatch }, payload) {
    commit("RESET_API_KEY_CREATE_ERRORS")
    try {
      let apiKey = await HttpService.post(UrlService.render("apiKey"), payload)
      const key = apiKey["api_key"]
      delete apiKey["api_key"]
      commit("SET_API_KEY", apiKey)
      if (payload.hasOwnProperty("partner_id")) {
        await dispatch("retrieveApiKeys", payload["partner_id"])
      } else {
        await dispatch("retrieveApiKeys")
      }
      // exceptionnellement, on va renvoyer une donnée à cet appel, car on ne peut pas conserver l'info
      // même dans le store
      return key
    } catch (e) {
      if (e instanceof HttpError) {
        if (e.status === 422) {
          commit("SET_API_KEY_CREATE_ERRORS", e.data)
          return
        }
      }
      console.error("failed: ", e)
      throw e
    }
  },
  openPermissionsApiKeyView({ commit }, apiKeyId) {
    commit("SET_CURRENT_API_KEY_ID", apiKeyId)
    let apiKeyIpsScope = []
    if (
      state.api_keys_by_id[apiKeyId].scope !== null &&
      state.api_keys_by_id[apiKeyId].scope.hasOwnProperty("ips")
    ) {
      apiKeyIpsScope = state.api_keys_by_id[apiKeyId].scope["ips"]
    }
    commit("SET_API_KEY_IPS_SCOPE", apiKeyIpsScope)
    let apiKeyUrlsScope = []
    if (
      state.api_keys_by_id[apiKeyId].scope !== null &&
      state.api_keys_by_id[apiKeyId].scope.hasOwnProperty("urls")
    ) {
      apiKeyUrlsScope = state.api_keys_by_id[apiKeyId].scope["urls"]
    }
    commit("SET_API_KEY_URLS_SCOPE", apiKeyUrlsScope)
    let apiKeyEndpointsScope = {}
    if (
      state.api_keys_by_id[apiKeyId].scope !== null &&
      state.api_keys_by_id[apiKeyId].scope.hasOwnProperty("endpoints")
    ) {
      apiKeyEndpointsScope = state.api_keys_by_id[apiKeyId].scope["endpoints"]
      commit("SET_API_KEY_INITIAL_ENDPOINTS_SCOPE", apiKeyEndpointsScope)
    }
  },
  // TODO: this is too much complicated -> avoid calling GET + review how to store security info
  async updateApiKeyPermissions({ commit, dispatch, rootGetters }, payload) {
    commit("RESET_API_KEY_UPDATE_ERRORS")
    const apiKeyId = state.api_key_permissions.current_api_key_id
    payload = {
      scope: state.api_key_permissions.scope,
      ...payload,
    }
    let partnerId = null
    try {
      partnerId = await HttpService.put(
        UrlService.render("apiKeyById", {
          id: apiKeyId,
        }),
        payload
      ).then((response) => {
        return response.partner.id
      })
    } catch (e) {
      if (e instanceof HttpError) {
        if (e.status === 422) {
          commit("SET_API_KEY_UPDATE_ERRORS", e.data)
          return
        }
      }
      console.error("failed: ", e)
      throw e
    } finally {
      if (rootGetters["auth/isInternalAdmin"]) {
        await dispatch("retrieveApiKeys", partnerId)
      } else {
        await dispatch("retrieveApiKeys")
      }
      dispatch("openPermissionsApiKeyView", apiKeyId)
    }
  },
  resetUpdateApiKeyErrors({ commit }) {
    commit("RESET_API_KEY_UPDATE_ERRORS")
  },
  setApiKeyIpsInScope({ commit }, ips) {
    commit("SET_API_KEY_IPS_SCOPE", ips)
  },
  setApiKeyUrlsInScope({ commit }, urls) {
    commit("SET_API_KEY_URLS_SCOPE", urls)
  },
  setApiKeyEndpointsInScope({ commit }, payload) {
    commit("SET_API_KEY_ENDPOINTS_SCOPE", payload)
  },
  async retrieveApiKey({ commit }, payload) {
    try {
      const apiKey = await HttpService.get(UrlService.render("apiKeyById", payload))
      commit("SET_API_KEY", apiKey)
    } catch (e) {
      console.error("failed: ", e)
      throw e
    }
  },
  async updateProgramRestriction({ commit }, payload) {
    commit("RESET_PROGRAM_RESTRICTION_UPDATE_ERRORS")
    try {
      payload.is_new_program_accessible = payload.default_behavior === "accessible"
      delete payload.default_behavior

      const apiKey = await HttpService.put(
        UrlService.render("programRestriction", { id: payload.id }),
        payload
      )
      commit("SET_API_KEY", apiKey)
    } catch (e) {
      console.error("failed: ", e)
      if (e instanceof HttpError) {
        if (e.status === 422) {
          commit("SET_PROGRAM_RESTRICTION_UPDATE_ERRORS", e.data)
          return
        }
      }
      throw e
    }
  },
  resetCreateErrors({ commit }) {
    commit("RESET_API_KEY_CREATE_ERRORS")
  },
}

// GETTERS (camelCase)
const getters = {
  getApiKeys: () => {
    return state.api_keys_by_id !== null ? state.api_keys_by_id : {}
  },
  getApiCreateErrors: () => state.create_errors,
  getApiKeyScopeUpdateErrors: () => {
    return state.api_key_permissions.api_key_update_errors !== null &&
      state.api_key_permissions.api_key_update_errors.hasOwnProperty("scope")
      ? state.api_key_permissions.api_key_update_errors.scope
      : null
  },
  getCurrentApiKey: () => state.api_key_permissions.current_api_key_id,
  getCurrentApiKeyScope: () => state.api_key_permissions.scope,
  isIpsRestrictionActivated: () => {
    return (
      state.api_key_permissions.scope.hasOwnProperty("ips") &&
      state.api_key_permissions.scope.ips.length > 0
    )
  },
  isUrlsRestrictionActivated: () => {
    return (
      state.api_key_permissions.scope.hasOwnProperty("urls") &&
      state.api_key_permissions.scope.urls.length > 0
    )
  },
  listIpsAddresses: () => {
    if (
      state.api_key_permissions.scope.hasOwnProperty("ips") &&
      state.api_key_permissions.scope["ips"].length > 0
    ) {
      return JSON.parse(JSON.stringify(state.api_key_permissions.scope.ips))
    }
    return []
  },
  listUrlsSources: () => {
    if (
      state.api_key_permissions.scope.hasOwnProperty("urls") &&
      state.api_key_permissions.scope["urls"].length > 0
    ) {
      return JSON.parse(JSON.stringify(state.api_key_permissions.scope.urls))
    }
    return []
  },
  listBlockedEndpoints: () => {
    if (state.api_key_permissions.scope.hasOwnProperty("endpoints")) {
      return state.api_key_permissions.scope.endpoints
    }
  },
  getApiKeyById: (state) => (apiKeyId) =>
    state.api_keys_by_id !== null && state.api_keys_by_id.hasOwnProperty(apiKeyId)
      ? JSON.parse(JSON.stringify(state.api_keys_by_id[apiKeyId]))
      : null,
  getApiKeyName: (state, getters) => (apiKeyId) => getters.getApiKeyById(apiKeyId).name,
  isProgramRestrictionActivated: (state) => (apiKeyId) =>
    state.api_keys_by_id !== null && state.api_keys_by_id.hasOwnProperty(apiKeyId)
      ? state.api_keys_by_id[apiKeyId].program_restriction.is_activated
      : false,
  isAccessRestrictionActivated: (state) => (apiKeyId) =>
    state.api_keys_by_id !== null && state.api_keys_by_id.hasOwnProperty(apiKeyId)
      ? state.api_keys_by_id[apiKeyId].access_restriction
      : false,
  getProgramRestrictionDefaultBehavior: (state) => (apiKeyId) =>
    state.api_keys_by_id !== null && state.api_keys_by_id.hasOwnProperty(apiKeyId)
      ? state.api_keys_by_id[apiKeyId].program_restriction.is_new_program_accessible
        ? "accessible"
        : "inaccessible"
      : null,
  getProgramRestrictionDefaultBehaviorOptions() {
    return {
      accessible: i18n.t("apiKeysResources.default_behavior_options.accessible"),
      inaccessible: i18n.t("apiKeysResources.default_behavior_options.inaccessible"),
    }
  },
  listPrograms: (state) => (apiKeyId) => {
    if (
      state.api_keys_by_id === null ||
      !state.api_keys_by_id.hasOwnProperty(apiKeyId)
    ) {
      return []
    }
    return state.api_keys_by_id[apiKeyId].program_restriction.programs
  },
  listAccessibleProgramsIds: (state) => (apiKeyId) => {
    if (
      state.api_keys_by_id === null ||
      !state.api_keys_by_id.hasOwnProperty(apiKeyId)
    ) {
      return []
    }
    return state.api_keys_by_id[apiKeyId].program_restriction.programs.reduce(
      (accessiblePrograms, program) => {
        if (program.is_accessible) {
          accessiblePrograms.push(program.id)
        }
        return accessiblePrograms
      },
      []
    )
  },
  getProgramRestrictionUpdateErrors: (state) => state.program_restriction_update_errors,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
