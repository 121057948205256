<template>
  <div>
    <div class="backdrop" @click.stop="$emit('close')"></div>
    <div class="tableline__actions">
      <ul>
        <li v-for="(link, i) in moreActionsLinks" :key="i">
          <router-link
            :class="{ disabled: !link.routeName }"
            :data-cy="`more-action-${link.code}`"
            :to="{ name: link.routeName, params: { id: item.id } }"
          >
            <span :class="'icon-' + link.icon"></span>
            {{ link.name }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "MoreActions",
  props: {
    item: {
      type: Object,
    },
    moreActionsLinks: {
      type: Array,
    },
  },
}
</script>

<style scoped lang="scss">
.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  cursor: initial;
}

.tableline__actions {
  position: absolute;
  right: 24px;
  top: 16px;
  z-index: 4;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  white-space: nowrap;

  ul {
    margin: 0;
    font-size: 1rem;
    line-height: 1.25rem;

    li {
      &:last-child {
        border-top: 1px solid $bleuc50;
      }

      a {
        display: flex;
        padding: 0.75rem 1rem;
        color: $primarybutton;

        &:hover {
          color: $bleucaarea;
        }

        &.disabled {
          cursor: not-allowed;

          &:hover {
            color: $primarybutton;
          }
        }

        span {
          margin-right: 0.75rem;
          color: $bleucaarea;
          font-size: 1.125rem;
        }
      }
    }
  }
}
</style>
