// STATES (snake_case)
import HttpService, { HttpError } from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"

const state = {
  partner_key_error: null,
  partner_key_not_found_error: null,
  is_visible: false,
  timeout_id: null,
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_PARTNER_KEY_ERROR(state, partnerKeyError) {
    state.partner_key_error = partnerKeyError
  },
  RESET_PARTNER_KEY_ERROR(state) {
    state.partner_key_error = null
  },
  SET_PARTNER_KEY_NOT_FOUND_ERROR(state, partnerKeyError) {
    state.partner_key_not_found_error = partnerKeyError
  },
  RESET_PARTNER_KEY_NOT_FOUND_ERROR(state) {
    state.partner_key_not_found_error = null
  },
  SET_IS_VISIBLE(state, isVisible) {
    state.is_visible = isVisible
  },
  SET_TIMEOUT_ID(state, timeoutId) {
    state.timeout_id = timeoutId
  },
}

// ACTIONS (camelCase)
const actions = {
  async retrievePartnerKey({ commit }, payload) {
    try {
      commit("RESET_PARTNER_KEY_ERROR")
      commit("RESET_PARTNER_KEY_NOT_FOUND_ERROR")
      return await HttpService.post(UrlService.render("partnerKey"), payload)
    } catch (e) {
      console.error("failed: ", e)
      if (e instanceof HttpError) {
        console.error("status: ", e.status)
        if (e.status === 422 || e.status === 400) {
          commit("SET_PARTNER_KEY_ERROR", e.data)
        }
        if (e.status === 404) {
          commit("SET_PARTNER_KEY_NOT_FOUND_ERROR", e.data)
        }
        return
      }
      throw e
    }
  },
  resetPartnerKeyError({ commit }) {
    commit("RESET_PARTNER_KEY_ERROR")
  },
  toggleVisibility({ commit }, isVisible) {
    commit("SET_IS_VISIBLE", isVisible)
  },
  setTimeoutId({ commit }, timeoutId) {
    commit("SET_TIMEOUT_ID", timeoutId)
  },
}

// GETTERS (camelCase)
const getters = {
  getPartnerKeyError: (state) => state.partner_key_error,
  getPartnerKeyNotFoundError: (state) => state.partner_key_not_found_error,
  isPartnerKeyVisible: (state) => state.is_visible,
  getTimeoutId: (state) => state.timeout_id,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
