<template>
  <div v-if="restrictionChecked" class="api-keys-security__restriction__body">
    <div
      v-for="(urlSource, index) in listUrlsSources"
      :key="index"
      class="api-keys-security__restriction__item"
      data-cy="api_key_security_url_item"
    >
      <p class="font-size-14" :data-cy="`api_key_${index}_security_url_item`">
        {{ urlSource }}
      </p>
      <span
        class="icon-trash2"
        @click="onDeleteUrlSourceClick(index)"
        :data-cy="`api_key_${index}_url_remove`"
      ></span>
    </div>
    <div class="api-keys-security__restriction__add-item">
      <FormInput
        class="api-keys-security__restriction__input"
        :name="`new-url-address-name`"
        :placeholder="$t('apiKeysSecurity.new_item_placeholder')"
        v-model="form.newUrlSource"
      ></FormInput>
      <ActionButton
        :button-class="['api-keys-security__restriction__button']"
        :button-text="$t('button.validate')"
        button-data-cy="add-url-address-button"
        @click-action="onAddUrlSourceClick"
      ></ActionButton>
    </div>
  </div>
</template>

<script>
import ActionButton from "@/components/button/ActionButton"
import { mapGetters } from "vuex"

export default {
  name: "ApiKeySecurityRestrictionsUrlItems",
  props: {
    restrictionChecked: { type: Boolean, required: true },
  },
  components: { ActionButton },
  computed: {
    ...mapGetters("apiKeys", ["listUrlsSources"]),
  },
  data() {
    return {
      form: { newUrlSource: "" },
    }
  },
  methods: {
    onDeleteUrlSourceClick(index) {
      let newUrlSources = [...this.listUrlsSources]
      newUrlSources.splice(index, 1)
      this.$store.dispatch("apiKeys/setApiKeyUrlsInScope", newUrlSources)
    },
    onAddUrlSourceClick() {
      const newUrlSources = [...this.listUrlsSources, this.form.newUrlSource]
      this.$store.dispatch("apiKeys/setApiKeyUrlsInScope", newUrlSources)
      this.form.newUrlSource = ""
    },
  },
}
</script>

<style scoped lang="scss">
.api-keys-security__restriction__body {
  padding-top: 20px;
  border-left: 1px solid $bleuc50;
  height: calc(100% - 55px - 20px);
}
.api-keys-security__restriction__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $bleuc50;
  border-bottom: 1px solid $bleuc50;
  margin-top: -1px;
  margin-left: 20px;
  padding: 0.5rem;

  p {
    margin: 0;
  }

  span {
    cursor: pointer;
  }
}

.api-keys-security__restriction__item:nth-child(2n + 1) {
  background-color: $caarealightgray;
}

.api-keys-security__restriction__add-item {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0 1rem 1rem;

  .api-keys-security__restriction__button {
    margin-left: 1rem;
  }
}

.api-keys-security__restriction__input {
  width: 100%;
  margin-right: 1rem;
}
</style>
