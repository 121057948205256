<template>
  <transition name="fade">
    <div class="modal" v-show="show" @click.self="$emit('close')">
      <div
        class="modal-content animate__animated animate__bounceInDown"
        :data-cy="modalName"
      >
        <div class="modal-header" :class="headerClass">
          <slot name="header" class="modal-header"></slot>
        </div>
        <div class="modal-body">
          <slot name="content"></slot>
        </div>
        <div class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  props: {
    show: { type: Boolean, defaults: false },
    headerClass: { type: String },
    modalName: { type: String },
  },
}
</script>

<style scoped lang="scss"></style>
