// STATES (snake_case)
import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"
import DateService from "@/services/technical/DateService"

const state = {
  partners: null,
  partners_formatted_list: [],
  selected_partner: null,
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_PARTNERS(state, partners) {
    state.partners = partners
  },
  SET_PARTNERS_FORMATTED_LIST(state, partnersListFromApi) {
    state.partners_formatted_list = partnersListFromApi
  },
  SET_SELECTED_PARTNER(state, partnerId) {
    state.selected_partner = partnerId
  },
}

// ACTIONS (camelCase)
const actions = {
  async initPartners({ commit, dispatch }) {
    try {
      let partners = await HttpService.get(UrlService.render("partners"))
      commit("SET_PARTNERS", partners)
      dispatch("setPartnersFormattedList", partners)
    } catch (e) {
      console.error("failed: ", e)
      throw e
    }
  },
  setPartnersFormattedList({ commit, rootGetters }, partnersList) {
    const partnersListToDisplay = partnersList.map((partner) => {
      const created_at = partner.created_at
        ? DateService.format(
            partner.created_at,
            "DD MMMM YYYY",
            rootGetters["config/getCurrentLang"]
          )
        : ""
      const created_by =
        partner.created_by.first_name + " " + partner.created_by.last_name
      return { ...partner, created_by, created_at }
    })
    commit("SET_PARTNERS_FORMATTED_LIST", partnersListToDisplay)
  },
  setSelectedPartner({ commit }, partnerId) {
    commit("SET_SELECTED_PARTNER", partnerId)
  },
  async retrievePartnerLinkedToDistributorAdmin({ commit }, userId) {
    try {
      const partner = await HttpService.get(
        UrlService.render("userPartner", { id: userId })
      )
      commit("SET_SELECTED_PARTNER", partner.id)
    } catch (e) {
      console.error("failed: ", e)
      throw e
    }
  },
}

// GETTERS (camelCase)
const getters = {
  listPartners: (state) => state.partners,
  listPartnersForSelectOptions: (state) => {
    if (state.partners !== null) {
      let partners = {}
      state.partners.map((partner) => {
        partners[partner.id] = partner.name
      })
      return partners
    }
    return {}
  },
  hasSelectedPartner: (state) => state.selected_partner !== null,
  getSelectedPartner: (state) => state.selected_partner,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
