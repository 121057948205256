import { render, staticRenderFns } from "./ProgramRestrictionTable.vue?vue&type=template&id=34068be5&scoped=true&"
import script from "./ProgramRestrictionTable.vue?vue&type=script&lang=js&"
export * from "./ProgramRestrictionTable.vue?vue&type=script&lang=js&"
import style0 from "./ProgramRestrictionTable.vue?vue&type=style&index=0&id=34068be5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34068be5",
  null
  
)

export default component.exports