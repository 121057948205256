<template>
  <div data-cy="program-restriction-table">
    <div class="item-container">
      <div>#</div>
      <div>
        {{ $t("apiKeysResources.table.headers.name") }}
      </div>
      <div>
        {{ $t("apiKeysResources.table.headers.creation") }}
      </div>
      <div>
        {{ $t("apiKeysResources.table.headers.author") }}
      </div>
      <div>
        {{ $t("apiKeysResources.table.headers.status") }}
      </div>
      <div>
        {{ $t("apiKeysResources.table.headers.switch") }}
      </div>
    </div>
    <div v-for="(program, index) in programs" class="item-container" :key="program.id">
      <div>{{ index + 1 }}</div>
      <div>{{ program.name }}</div>
      <div>{{ program.created_at }}</div>
      <div>{{ program.created_by }}</div>
      <div>{{ program.status }}</div>
      <div>
        <label class="switch">
          <input
            type="checkbox"
            :id="`program-${program.id}-accessibility-checkbox`"
            :checked="program.is_accessible"
            @change="onSwitchClick(program)"
            :data-cy="`program-${program.id}-accessibility-checkbox`"
          />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgramRestrictionTable",
  props: {
    programs: { type: Array, required: true },
  },
  methods: {
    onSwitchClick(program) {
      const isAccessible = document.getElementById(
        `program-${program.id}-accessibility-checkbox`
      ).checked
      const payload = { id: program.id, isAccessible: isAccessible }
      this.$emit("program-accessiblity-change", payload)
    },
  },
}
</script>

<style scoped lang="scss">
.item-container {
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 5fr 3fr 5fr;
  align-items: center;
  font-family: "Effra Regular", sans-serif;
  font-size: 15px;
  padding: 0.375rem 0.5rem;
  border-bottom: 1px solid #c0c8d7;
}
.item-container:nth-child(2n) {
  background-color: $caarealightgray;
}
</style>
