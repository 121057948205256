<template>
  <div>
    <spinner v-if="isLoading" :no-message="true"></spinner>
    <div v-else>
      <ApiKeySettingsHeader
        :api-key-id="id"
        :section-title="$t('apiKeysResources.title')"
        :description="$t('apiKeysResources.description')"
        :instructions="$t('apiKeysResources.activation_instructions')"
        :is-checked="isRestrictionActivated"
        data-cy-prefix="program-restriction"
        @switch-clicked="onSwitchClick()"
      ></ApiKeySettingsHeader>
      <div v-if="isRestrictionActivated">
        <div
          class="restriction-default-behavior"
          data-cy="restriction-default-behavior"
        >
          <div class="restriction-default-behavior__left-insert"></div>
          <div class="restriction-default-behavior__content">
            <div class="restriction-default-behavior__content__title font-size-16">
              {{ $t("apiKeysResources.default_behavior_title") }}
            </div>
            <div class="restriction-default-behavior__content__selection">
              <FormRowSelect
                class="default-behavior-select"
                name="default-behavior-selection"
                :label="$t('apiKeysResources.default_behavior_selection')"
                label-select-attr="name"
                :label-class="[
                  'align-self-center',
                  'restriction-default-behavior__content__selection__label',
                ]"
                :control-class="['col-sm-4', 'align-self-center']"
                :select-options="getProgramRestrictionDefaultBehaviorOptions"
                :selected-option.sync="selectedDefaultBehavior"
              ></FormRowSelect>
            </div>
          </div>
        </div>
        <div class="manual-assignment" data-cy="manual-assignment">
          <div class="manual-assignment__left-insert"></div>
          <div class="manual-assignment__title font-size-16">
            {{ $t("apiKeysResources.manual_assignment_title") }}
          </div>
        </div>
        <div class="restriction-table__container">
          <ProgramRestrictionTable
            v-if="isManualAssignmentPossible"
            :programs="listPrograms(id)"
            @program-accessiblity-change="onProgramAccessibilityChange($event)"
          ></ProgramRestrictionTable>
          <div v-else class="no-program">
            {{ $t("apiKeysResources.manual_assignment_impossible") }}
          </div>
        </div>
      </div>
      <ActionButton
        class="save-button"
        :button-text="$t('button.save')"
        @click-action="onSaveButtonClick"
        button-data-cy="save-button"
      ></ActionButton>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ActionButton from "@/components/button/ActionButton"
import ProgramRestrictionTable from "@/components/apiKeys/resources/ProgramRestrictionTable"
import ArrayService from "../../../services/technical/ArrayService"
import ApiKeySettingsHeader from "@/components/apiKeys/utils/ApiKeySettingsHeader"

export default {
  name: "ApiKeyResources",
  components: {
    ApiKeySettingsHeader,
    ActionButton,
    ProgramRestrictionTable,
  },
  props: {
    id: { type: [Number, String] },
  },
  data() {
    return {
      isLoading: true,
      isRestrictionActivated: false,
      selectedDefaultBehavior: null,
      accessibleProgramsIds: [],
      showWarningModal: false,
    }
  },
  computed: {
    ...mapGetters("apiKeys", [
      "getApiKeyById",
      "isProgramRestrictionActivated",
      "getProgramRestrictionDefaultBehavior",
      "getProgramRestrictionDefaultBehaviorOptions",
      "listPrograms",
      "listAccessibleProgramsIds",
      "getProgramRestrictionUpdateErrors",
    ]),
    isManualAssignmentPossible() {
      return this.listPrograms(this.id).length > 0
    },
    updateFailureReason() {
      return Object.entries(this.getProgramRestrictionUpdateErrors).reduce(
        (reason, [key, value]) => {
          return `${key}: ${value}`
        },
        ""
      )
    },
  },
  methods: {
    onSwitchClick() {
      this.isRestrictionActivated = !this.isRestrictionActivated
    },
    onProgramAccessibilityChange(changeData) {
      if (changeData.isAccessible) {
        ArrayService.pushIfNotExist(this.accessibleProgramsIds, changeData.id)
      } else {
        this.accessibleProgramsIds = this.accessibleProgramsIds.filter(
          (programId) => programId !== changeData.id
        )
      }
    },
    async onSaveButtonClick() {
      let payload = {
        id: this.id,
        is_activated: this.isRestrictionActivated,
        default_behavior: this.selectedDefaultBehavior,
        accessible_programs_ids: this.accessibleProgramsIds.sort(),
      }
      await this.$store.dispatch("apiKeys/updateProgramRestriction", payload)
      if (this.getProgramRestrictionUpdateErrors === null) {
        this.$store.dispatch("gui/setApiKeyUpdateFeedbackModalInfo", {
          title: this.$t("apiKeysResources.feedback_modal.title"),
          message: this.$t("apiKeysResources.feedback_modal.message"),
        })
        this.$store.dispatch("gui/showApiKeyUpdateFeedbackModal")
      } else {
        this.$store.dispatch("gui/setApiKeyUpdateWarningModalInfo", {
          title: this.$t("apiKeysResources.warning_modal.title"),
          reason: this.updateFailureReason,
        })
        this.$store.dispatch("gui/showApiKeyUpdateWarningModal")
      }
      this.initData()
    },
    initData() {
      this.isRestrictionActivated = this.isProgramRestrictionActivated(this.id)
      this.selectedDefaultBehavior = this.getProgramRestrictionDefaultBehavior(this.id)
      this.accessibleProgramsIds = this.listAccessibleProgramsIds(this.id)
    },
  },
  async created() {
    this.isLoading = true
    await this.$store.dispatch("apiKeys/retrieveApiKey", { id: this.id })
    this.initData()
    this.isLoading = false
  },
}
</script>

<style scoped lang="scss">
.restriction-activation {
  margin-top: 20px;
  background: #fff;
  display: grid;
  grid-template-columns: 6px auto;
  &__left-insert {
    background: $bleuc400;
  }
  &__content {
    padding: 10px 20px;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-areas: "text activation";

    &__text {
      grid-area: text;
      display: grid;
      grid-template-rows: repeat(2, auto);
      grid-template-areas:
        "title"
        "desc";
      align-items: center;

      &__title {
        grid-area: title;
      }
      &__desc {
        grid-area: desc;
        font-family: "Effra Light", sans-serif;
      }
    }

    &__activation {
      grid-area: activation;
      display: grid;
      grid-template-columns: 3fr 1fr;
      align-items: center;

      &__text {
        font-family: "Effra Light", sans-serif;
        justify-self: center;
      }

      &__switch {
        justify-self: end;
      }
    }
  }
}

.restriction-default-behavior {
  margin-top: 1px;
  background: #fff;
  display: grid;
  grid-template-columns: 6px auto;

  &__left-insert {
    background: $secondary500;
  }
  &__content {
    padding: 10px 20px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-areas: "title selection";
    align-items: center;

    &__title {
      grid-area: title;
    }

    &__selection {
      grid-area: selection;

      &__label {
        font-family: "Effra Light", sans-serif;
        font-size: 15px;
      }
    }
  }
}

.default-behavior-select {
  justify-content: flex-end !important;
  column-gap: 20px;
  // due to this f... bootstrap !
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.manual-assignment {
  margin-top: 1px;
  background: #fff;
  display: grid;
  grid-template-columns: 6px auto;

  &__left-insert {
    background: $secondary500;
  }

  &__title {
    padding: 20px;
  }
}

.restriction-table__container {
  background: #fff;
  padding: 10px 20px 20px;

  .no-program {
    text-align: center;
  }
}

.save-button {
  margin: 2rem 0;
  text-align: center;
}
</style>
