<template>
  <div class="apikey-details">
    <div class="apikey_details" data-cy="apikey-details">
      <div class="apikey-summary">
        <div class="apikey-summary__name">
          <h5 class="apikey__data-name">
            {{ $t("apikeys.name") }}
          </h5>
          <h3 class="apikey__data-value" :data-cy="`apikey-name-${keyInfo.id}`">
            {{ keyInfo.name }}
          </h3>
        </div>
        <div class="apikey-summary__creation-date">
          <h5 class="apikey__data-name">{{ $t("apikeys.creation_date") }}</h5>
          <h3 class="apikey__data-value" :data-cy="`apikey-created-at-${keyInfo.id}`">
            {{ keyInfo.created_at }}
          </h3>
        </div>
        <div class="apikey-summary__created-by">
          <h5 class="apikey__data-name">{{ $t("apikeys.created_by") }}</h5>
          <h3 class="apikey__data-value" :data-cy="`apikey-created-by-${keyInfo.id}`">
            {{ keyInfo.created_by }}
          </h3>
        </div>
        <div class="apikey-summary__status">
          <h5 class="apikey__data-name">{{ $t("apikeys.status") }}</h5>
          <div class="apikey-summary__status-bg">
            <h3 class="apikey__data-status" :data-cy="`apikey-status-${keyInfo.id}`">
              {{ keyInfo.status }}
            </h3>
          </div>
        </div>
        <div class="apikey-summary__dots" @click="onMoreClick">
          <h3
            class="apikey__data-dots"
            :data-cy="`api-key-${keyInfo.id}-more-actions-button`"
          >
            ...
          </h3>
          <MoreActions
            v-if="isMoreActionsOpened"
            :more-actions-links="actions"
            :item="keyInfo"
            @close="onCloseMoreActions"
          ></MoreActions>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MoreActions from "@/components/button/MoreActions"
export default {
  name: "ApiKeyDetails",
  components: { MoreActions },
  props: {
    keyInfo: { type: Object, required: true },
  },
  data() {
    return {
      isMoreActionsOpened: false,
      actions: [
        {
          name: this.$t("apikeys.more_actions.rename"),
          routeName: "",
          code: "rename",
          icon: "pencil5",
        },
        {
          name: this.$t("apikeys.more_actions.use_case"),
          routeName: "",
          code: "use_case",
          icon: "chart-bars",
        },
        {
          name: this.$t("apikeys.more_actions.access"),
          routeName: "api-key-access",
          code: "access",
          icon: "lock",
        },
        {
          name: this.$t("apikeys.more_actions.resources"),
          routeName: "api-key-resources",
          code: "resources",
          icon: "share2",
        },
        {
          name: this.$t("apikeys.more_actions.security"),
          routeName: "api-key-security",
          code: "security",
          icon: "prohibited",
        },
        {
          name: this.$t("apikeys.more_actions.disable"),
          routeName: "",
          code: "disable",
          icon: "trash2",
        },
      ],
    }
  },
  methods: {
    onMoreClick() {
      this.isMoreActionsOpened = true
    },
    onCloseMoreActions() {
      this.isMoreActionsOpened = false
    },
  },
}
</script>

<style scoped lang="scss">
.apikey-details {
  border-bottom: 1px solid #e7eaf0;
  &:nth-last-child(-n + 2) {
    border: initial;
  }
}

.apikey_details {
  .apikey-summary {
    background-color: white;
    padding: 12px 20px 8px 36px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr;
    grid-template-areas: "name creation-date created-by status dots";
    h5 {
      font-family: "Effra Light", sans-serif;
      font-size: 11px;
      margin: 0 0 5px;
    }
    h3 {
      font-family: "Gilroy Medium", sans-serif;
      font-size: 16px;
      margin: 0;
    }
    .apikey-summary__name {
      grid-area: name;
    }
    .apikey-summary__created-by {
      grid-area: created-by;
    }
    .apikey-summary__status {
      grid-area: status;
      .apikey-summary__status-bg {
        border: 1px solid #01a39d;
        border-radius: 9px;
        background: #01a39d;
        display: inline-block;
        padding: 1px 12px;
      }
      .apikey__data-status {
        text-transform: lowercase;
        margin: 0;
        color: #fff;
      }
    }
    .apikey-summary__dots {
      grid-area: dots;
      display: flex;
      flex-direction: row-reverse;
      position: relative;
      cursor: pointer;
      h3 {
        font-size: 40px;
        transform: rotate(90deg);
        margin: 0;
        text-align: center;
        grid-row-start: 2;
      }
    }
  }
}
</style>
