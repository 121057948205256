<template>
  <transition v-if="visible" name="slide-fade">
    <div id="sidenav" data-cy="burger_menu">
      <div class="cross-button">
        <button
          type="button"
          class="invisible-button close-cross icon-cross"
          aria-hidden="true"
          @click.prevent="$emit('toggle-menu-visibility')"
          data-cy="menu-close"
        ></button>
      </div>
      <nav>
        <ul class="menu" @click="$emit('toggle-menu-visibility')">
          <li v-for="item in listBurgerItems" :key="item.name">
            <router-link
              :data-cy="`burger_link_${item.name}`"
              :to="{ name: item.routeName, params: { from: 'burgermenu' } }"
              class="menu-item"
            >
              {{ $t(`burgermenu.${item.name}`) }}
            </router-link>
          </li>
          <li class="menu-items-separator"><span>&nbsp;</span></li>
          <li>
            <router-link
              data-cy="burger_disconnect_link"
              :to="{ name: 'logout' }"
              class="menu-item"
            >
              {{ $t("burgermenu.disconnect") }}
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </transition>
</template>

<script>
export default {
  name: "BurgerMenu",
  props: ["visible"],
  data() {
    return {
      listBurgerItems: [
        { name: "home", routeName: "home" },
        { name: "api_sales", routeName: "apisales" },
        { name: "api_mycaarea", routeName: "apimycaarea" },
        { name: "api_claims", routeName: "apiclaims" },
        { name: "api_monitoring", routeName: "apimonitoring" },
        { name: "api_keys", routeName: "api-keys-list" },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s;
}
.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(100%);
}
.menu {
  font-family: "Effra Regular";
  font-size: 0.938rem;
  line-height: 18px;
  letter-spacing: 0.09rem;
  text-align: right;
}

.menu-item:hover {
  font-family: "Effra SemiBold";
}
.menu-item {
  transition: all 0.3s;
}
.close-cross {
  color: white;
}

.cross-button {
  display: grid;
  margin-top: 1rem;
  grid-template-rows: 1fr;
  justify-content: end;
}

.menu-items-separator {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
