export default {
  computed: {
    isFullLayout() {
      return this.$route.meta.hasOwnProperty("fullLayout")
        ? this.$route.meta.fullLayout
        : false
    },
    layoutBgColor() {
      return this.$route.meta.hasOwnProperty("layoutBackgroundColor")
        ? `background-color: ${this.$route.meta.layoutBackgroundColor}`
        : ""
    },
  },
}
