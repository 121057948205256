<template>
  <footer>
    <div class="container">
      <div class="footer-row">
        <div class="col-auto mr-auto">
          &copy; CAAREA. All rights reserved.
          <template v-if="version">v{{ version }}</template>
        </div>
        <div class="col-auto">
          <a href="">{{ $t("footer.legal_notice") }}</a>
          <a href="">{{ $t("footer.privacy_policy") }}</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "LayoutFooter",
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    }
  },
}
</script>

<style lang="scss" scoped>
.footer-row {
  display: flex;
  justify-content: space-between;
  margin: 0 5rem;
}
</style>
