<template>
  <div>
    <PartnerKey></PartnerKey>
    <ApiKeysList></ApiKeysList>
  </div>
</template>
<script>
import PartnerKey from "@/components/apiKeys/home/PartnerKey"
import ApiKeysList from "@/components/apiKeys/home/ApiKeysList"

export default {
  components: { PartnerKey, ApiKeysList },
  name: "ApiKeys",
}
</script>

<style scoped lang="scss"></style>
