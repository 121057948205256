<template>
  <div id="presentation">
    <h2 data-cy="api-title" class="title">
      {{ $t("home.blockAPI.title") }}
    </h2>
    <div data-cy="four-blocks" class="api-list">
      <itemBlock
        v-for="api in apis"
        :key="api.id"
        :logo="api.logo"
        :route="api.id"
        :title="$t(`home.blockAPI.${api.id}.name`)"
        :description="$t(`home.blockAPI.${api.id}.description`)"
      />
    </div>
    <div data-cy="action-verb" class="actions-list">
      <itemAction
        v-for="action in actionVerbs"
        :key="action"
        :verb="$t(`home.actionCircle.${action}.verb`)"
        :description="$t(`home.actionCircle.${action}.description`)"
      />
    </div>
  </div>
</template>

<script>
import ItemBlock from "@/components/homePage/itemBlock"
import ItemAction from "@/components/homePage/itemAction"

export default {
  components: { ItemBlock, ItemAction },
  data() {
    return {
      apis: [
        { id: "apisales", logo: "icon-coin-dollar" },
        { id: "apimycaarea", logo: "icon-user" },
        { id: "apiclaims", logo: "icon-warning" },
        { id: "apimonitoring", logo: "icon-signal" },
      ],
      actionVerbs: ["explore", "consult", "test", "contact"],
    }
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-family: "Gilroy SemiBold", sans-serif;
  font-weight: bold;
  font-size: 36px;
  letter-spacing: 0.09em;
  padding: 5rem 0 3rem 1rem;
  text-align: center;

  &:before {
    content: "";
    position: relative;
    display: inline-block;
    height: 30px;
    border-left: 2px solid $bleucaarea;
    padding-right: 3px;
  }
}
.api-list {
  padding: 0 50px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
  max-width: 1200px;
  margin: auto;
  letter-spacing: 1px;
}
.actions-list {
  margin: 5rem auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  max-width: 730px;
}

@media only screen and (max-width: 960px) {
  .api-list {
    padding: 0 50px;
    grid-template-columns: repeat(2, 1fr);
    max-width: 600px;
  }
  .actions-list {
    grid-gap: 15px;
  }
}
</style>
