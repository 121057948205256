<template>
  <ModalBase :show="true" :modal-name="modalName">
    <template #header>
      <div class="confirm_modal_container confirm_modal_container-header">
        <h3 class="modal-title">{{ $t("apikeys.api_key_modal_name_title") }}</h3>
        <button
          type="button"
          class="modal-btn-close modal-cross icon-cross align-self-center"
          @click="onClose"
          data-cy="close-button"
        ></button>
      </div>
    </template>
    <template #content>
      <div class="confirm_modal_container confirm_modal_container-content">
        <div class="flex justify-content-center">
          <p class="impact_msg">{{ $t("apikeys.api_key_modal_name_msg") }}</p>
          <FormInput
            v-model="name"
            name="name"
            type="text"
            :placeholder="$t('apikeys.name')"
            :errors="getApiCreateErrors"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="confirm_modal_container confirm_modal_container-footer horizontal">
        <ActionButton
          :button-text="$t('apikeys.validate')"
          @click-action="onValidateName"
          button-data-cy="validate-api-name-button"
          :disabled="disabledButton"
        ></ActionButton>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import ModalBase from "@/components/modal/ModalBase"
import ActionButton from "@/components/button/ActionButton"
import { mapGetters } from "vuex"

export default {
  name: "ApiKeyNameModal",
  components: { ActionButton, ModalBase },
  props: {
    modalName: { type: String },
  },
  computed: {
    ...mapGetters("apiKeys", ["getApiCreateErrors"]),
    ...mapGetters("partner", ["hasSelectedPartner", "getSelectedPartner"]),
    disabledButton() {
      return this.name === ""
    },
  },
  data() {
    return {
      name: "",
    }
  },
  methods: {
    onClose() {
      this.$store.dispatch("apiKeys/resetCreateErrors")
      this.$emit("close")
    },
    async onValidateName() {
      let payload = {}
      payload["name"] = this.name
      if (this.hasSelectedPartner) {
        payload["partner_id"] = this.getSelectedPartner
      }
      let api_key = await this.$store.dispatch("apiKeys/create", payload)
      if (this.getApiCreateErrors === null) {
        this.$emit("api-key-created", api_key)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.confirm_modal_container-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .modal-cross {
    color: $bleuc400;
    transition: all 0.3s;
    cursor: pointer;
  }
  .modal-btn-close {
    font-size: 1.5rem;
    position: relative;
    border: none;
    background: transparent;
  }
  .align-self-center {
    align-self: center !important;
  }
  .modal-title {
    margin: auto;
    display: block;
  }
}

.confirm_modal_container {
  .impact_msg {
    text-align: center;
    white-space: pre-line;
  }
}
.confirm_modal_container-footer {
  div {
    text-align: center;
  }
  margin-bottom: 50px;
}
.confirm_modal_container-content {
  margin-bottom: 15px;
  .justify-content-center {
    div {
      width: 50%;
      margin: 0 auto;
    }
  }
}
</style>
