<template>
  <div>
    <div class="background-caarea">
      <Spinner v-if="isLoading" class="spinner"></Spinner>
      <div v-else>
        <div class="welcome-text">
          <h1 class="text-white absolute welcome" data-cy="welcome">
            {{ $t("home.welcome") }}
          </h1>
          <h2 class="text-white absolute caarea-space" data-cy="caarea-space">
            {{ $t("home.caareaSpace") }}
          </h2>
          <div class="scroll-cta" v-show="ctaIsVisible" @click="scrollToAPIBlocks">
            <p data-cy="cta-text">{{ $t("home.ctaAPI") }}</p>
            <span data-cy="cta-arrow" class="icon-chevron-down"></span>
          </div>
        </div>
        <transition appear name="slide">
          <div
            id="overlay"
            data-cy="cookies-overlay"
            v-if="!user.profile.accepted_cookies"
          >
            <p class="cookies-title" data-cy="cookies-title">
              {{ $t("home.cookies.title") }}
            </p>
            <div class="cookies-part2">
              <p class="cookies-part2__desc-1" data-cy="cookies-description">
                {{ $t("home.cookies.description") }}
              </p>
              <div class="cookies-part2__desc-2-and-button">
                <p data-cy="cookies-description-2">
                  {{ $t("home.cookies.description2") }}
                </p>
                <div class="cookies-part2__desc-2-and-button__button">
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-toggle="modal"
                    data-target=".bd-example-modal-lg"
                    @click="onClickAcceptCookies"
                    data-cy="accept-cookies"
                  >
                    {{ $t("button.accept") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <ApiBlocks />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ApiBlocks from "@/components/homePage/ApiBlocks"

export default {
  name: "Home",
  components: {
    ApiBlocks,
  },
  data() {
    return {
      isLoading: true,
      user: null,
      scrollAreaSize: null,
      offsetHeight: null,
      ctaIsVisible: true,
    }
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
  },
  methods: {
    async onClickAcceptCookies() {
      await this.$store.dispatch("auth/acceptCookies", this.user.id)
      this.user = this.getCurrentUser
    },
    scrollToAPIBlocks() {
      const divToGo = this.$el.querySelector("#presentation")
      divToGo.scrollIntoView({ behavior: "smooth" })
    },
    handleScroll(event) {
      const scrollHeight = document.documentElement.scrollHeight
      const scrollAreaSize = scrollHeight - this.offsetHeight
      this.ctaIsVisible = scrollAreaSize !== window.scrollY
    },
  },
  async created() {
    window.addEventListener("scroll", this.handleScroll)
    this.offsetHeight = document.documentElement.offsetHeight
    this.user = this.getCurrentUser
    this.isLoading = false
  },
  mounted() {
    if (this.$route.params.from === "burgermenu") {
      this.scrollToAPIBlocks()
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll)
  },
}
</script>

<style lang="scss" scoped>
.background-caarea {
  position: relative;
  top: 60px;
  height: 90vh;
  width: 100%;
  background-image: url("https://res.cloudinary.com/caarea/image/upload/v1617955698/cs/assets/splash-screen-caarea_i7zhaa.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.scroll-cta {
  color: $caareagray;
  text-decoration: none;
  font-size: 18px;
  letter-spacing: 2px;
  position: absolute;
  bottom: 15px;
  text-align: center;

  p {
    margin: 0.1rem;
  }
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.welcome-text {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  color: white;
}

.absolute {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
}

.welcome {
  top: 35%;
  font-weight: normal;
  font-size: 65px;
}

.caarea-space {
  top: 63%;
  font-weight: normal;
  font-size: 32px;
}

#overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 25%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(106, 102, 94, 0.7);
  z-index: 2;
  color: white;
}

.cookies-title {
  padding-left: 1.5rem;
  padding-top: 1rem;
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 0;
}

.cookies-part2 {
  padding: 0 3rem 2rem 3rem;
}

.cookies-part2__desc-1 {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 1rem;
}

.cookies-part2__desc-2-and-button {
  display: grid;
  grid-template-columns: 5fr 1fr;
}

.cookies-part2__desc-2-and-button__button {
  justify-self: end;
  align-self: end;
}
</style>
