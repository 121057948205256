// STATES (snake_case)
import Vue from "vue"
import i18n from "../i18n"

const state = {
  show_api_key_update_feedback_modal: false,
  api_key_update_feedback_modal_info: {
    title: "",
    message: "",
  },
  show_api_key_update_warning_modal: false,
  api_key_update_warning_modal_info: {
    title: "",
    reason: "",
  },
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_SHOW_API_KEY_UPDATE_FEEDBACK_MODAL(state, status) {
    state.show_api_key_update_feedback_modal = status
  },
  SET_API_KEY_UPDATE_FEEDBACK_MODAL_INFO(state, payload) {
    state.api_key_update_feedback_modal_info = payload
  },
  RESET_API_KEY_UPDATE_FEEDBACK_MODAL_INFO(state, payload) {
    Object.keys(state.api_key_update_feedback_modal_info).forEach(
      (key) => (state.api_key_update_feedback_modal_info[key] = "")
    )
  },
  SET_SHOW_API_KEY_UPDATE_WARNING_MODAL(state, status) {
    state.show_api_key_update_warning_modal = status
  },
  SET_API_KEY_UPDATE_WARNING_MODAL_INFO(state, payload) {
    state.api_key_update_warning_modal_info = payload
  },
  RESET_API_KEY_UPDATE_WARNING_MODAL_INFO(state, payload) {
    Object.keys(state.api_key_update_warning_modal_info).forEach(
      (key) => (state.api_key_update_warning_modal_info[key] = "")
    )
  },
}

// ACTIONS (camelCase)
const actions = {
  showApiKeyUpdateFeedbackModal({ commit }) {
    commit("SET_SHOW_API_KEY_UPDATE_FEEDBACK_MODAL", true)
  },
  hideApiKeyUpdateFeedbackModal({ commit }) {
    commit("SET_SHOW_API_KEY_UPDATE_FEEDBACK_MODAL", false)
  },
  setApiKeyUpdateFeedbackModalInfo({ commit }, payload) {
    commit("SET_API_KEY_UPDATE_FEEDBACK_MODAL_INFO", payload)
  },
  resetApiKeyUpdateFeedbackModalInfo({ commit }) {
    commit("RESET_API_KEY_UPDATE_FEEDBACK_MODAL_INFO")
  },
  showApiKeyUpdateWarningModal({ commit }) {
    commit("SET_SHOW_API_KEY_UPDATE_WARNING_MODAL", true)
  },
  hideApiKeyUpdateWarningModal({ commit }) {
    commit("SET_SHOW_API_KEY_UPDATE_WARNING_MODAL", false)
  },
  setApiKeyUpdateWarningModalInfo({ commit }, payload) {
    commit("SET_API_KEY_UPDATE_WARNING_MODAL_INFO", payload)
  },
  resetApiKeyUpdateWarningModalInfo({ commit }) {
    commit("RESET_API_KEY_UPDATE_WARNING_MODAL_INFO")
  },
}

// GETTERS (camelCase)
const getters = {
  mustApiKeyUpdateFeedbackModalBeDisplayed: (state) =>
    state.show_api_key_update_feedback_modal,
  getApiKeyUpdateFeedbackModalInfo: (state) => state.api_key_update_feedback_modal_info,
  mustApiKeyUpdateWarningModalBeDisplayed: (state) =>
    state.show_api_key_update_warning_modal,
  getApiKeyUpdateWarningModalInfo: (state) => state.api_key_update_warning_modal_info,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
