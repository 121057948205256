import AbstractService from "@/services/AbstractService"

class ArrayUtils extends AbstractService {
  /**
   * Adds an element to the array if it does not already exist
   *
   * @param baseArray
   * @param value
   */
  pushIfNotExist(baseArray, value) {
    if (!baseArray.includes(value)) {
      baseArray.push(value)
    }
    return baseArray
  }
}

let ArrayService = new ArrayUtils()
export default ArrayService
