<template>
  <div class="api__doc" data-cy="api-doc">
    <div v-if="!swaggerUrl" class="api__doc__unavailable" data-cy="doc-unavailable">
      {{ $t("apidoc.apiUnavailable") }}
    </div>
    <div v-else class="swagger" id="swagger"></div>
  </div>
</template>

<script>
import SwaggerUI from "swagger-ui"
import "swagger-ui/dist/swagger-ui.css"

export default {
  data() {
    return {
      swaggerUrl: process.env[this.$router.currentRoute.meta.urlSwagger],
    }
  },
  mounted() {
    if (this.swaggerUrl !== "") {
      SwaggerUI({
        url: this.swaggerUrl,
        dom_id: "#swagger",
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.api__doc {
  margin: 24px 24px 0 279px;
  width: 100%;
  background-color: white;

  &__unavailable {
    text-align: center;
    margin: 50px auto;
  }
}
</style>
