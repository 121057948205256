import Vue from "vue"
import VueI18n from "vue-i18n"
import en from "./en"
import fr from "./fr"
import es from "./es"
import ko from "./ko"

const messages = {
  en: en,
  fr: fr,
  es: es,
  ko: ko,
}

Vue.use(VueI18n)

export default new VueI18n({
  locale: "fr",
  fallbackLocale: "fr",
  messages,
})
