<template>
  <ModalBase
    :show="true"
    :modal-name="modalName"
    header-class="apikey_unique_modal__header"
  >
    <template #header>
      <div class="confirm_modal_container confirm_modal_container-header">
        <h3 class="modal-title">{{ $t("apikeys.your_api_key") }}</h3>
      </div>
    </template>
    <template #content>
      <div class="confirm_modal_container confirm_modal_container-content">
        <div class="flex justify-content-center">
          <p class="impact_msg">
            <span>{{ $t("apikeys.warning") }}</span>
            {{ $t("apikeys.api_key_modal_unique_msg") }}
          </p>
          <FormInput
            name="api-key-unique"
            type="text"
            disabled="disabled"
            :value="apiUniqueKey"
            input-class="apikey_unique_modal__input"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="confirm_modal_container confirm_modal_container-footer horizontal">
        <ActionButton
          :button-text="$t('apikeys.next')"
          @click-action="onClose"
          button-data-cy="next-api-unique-button"
        ></ActionButton>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import ModalBase from "@/components/modal/ModalBase"
import ActionButton from "@/components/button/ActionButton"

export default {
  name: "ApiKeyUniqueModal",
  components: { ActionButton, ModalBase },
  props: {
    apiUniqueKey: { type: String, required: true },
    modalName: { type: String },
  },
  methods: {
    onClose() {
      this.$emit("close")
    },
  },
}
</script>

<style scoped lang="scss">
.confirm_modal_container-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .modal-cross {
    color: $bleuc400;
    transition: all 0.3s;
    cursor: pointer;
  }
  .modal-btn-close {
    font-size: 1.5rem;
    position: relative;
    border: none;
    background: transparent;
  }
  .align-self-center {
    align-self: center !important;
  }
  .modal-title {
    margin: auto;
    display: block;
  }
}

.confirm_modal_container {
  h3 {
    color: #fff;
    text-align: center;
    font-family: "Effra Light", sans-serif;
    font-size: 35px;
  }
  .impact_msg {
    text-align: center;
    white-space: pre-line;
    span {
      font-family: "Gilroy SemiBold", sans-serif;
    }
  }
}
.confirm_modal_container-footer {
  div {
    text-align: center;
  }
  margin-bottom: 50px;
}
.confirm_modal_container-content {
  margin-bottom: 15px;
  .justify-content-center {
    div {
      width: 50%;
      margin: 0 auto;
    }
  }
}
</style>
