<template>
  <div class="header" :data-cy="`${dataCyPrefix}-activation`">
    <div class="header__left-insert"></div>
    <div class="header__content">
      <div class="header__content__text">
        <div class="header__content__text__title font-size-16">
          {{ title }}
        </div>
        <div class="header__content__text__desc font-size-11">
          {{ description }}
        </div>
      </div>
      <div v-if="mustActivationSectionBeDisplayed" class="header__content__activation">
        <div class="header__content__activation__text">
          <label for="activation-switch">{{ instructions }}</label>
        </div>
        <div
          class="header__content__activation__switch"
          :data-cy="`${dataCyPrefix}-switch`"
        >
          <label class="switch">
            <input
              id="activation-switch"
              type="checkbox"
              :checked="isChecked"
              @change="onSwitchClick"
              :data-cy="`${dataCyPrefix}-checkbox`"
            />
            <span class="slider round"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "ApiKeySettingsHeader",
  props: {
    apiKeyId: { type: [Number, String], required: true },
    sectionTitle: { type: String, required: true },
    description: { type: String, required: true },
    instructions: { type: String, default: null },
    isChecked: { type: Boolean, default: false },
    dataCyPrefix: { type: String, required: true },
  },
  computed: {
    ...mapGetters("apiKeys", ["getApiKeyName"]),
    title() {
      return `${this.getApiKeyName(this.apiKeyId)} - ${this.sectionTitle}`
    },
    mustActivationSectionBeDisplayed() {
      return this.instructions !== null
    },
  },
  methods: {
    onSwitchClick() {
      this.$emit("switch-clicked")
    },
  },
}
</script>

<style scoped lang="scss">
.header {
  margin-top: 20px;
  background: #fff;
  display: grid;
  grid-template-columns: 6px auto;
  &__left-insert {
    background: $bleuc400;
  }
  &__content {
    padding: 13px 20px;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-areas: "text activation";

    &__text {
      grid-area: text;
      display: grid;
      grid-template-rows: repeat(2, auto);
      grid-template-areas:
        "title"
        "desc";
      align-items: center;
      row-gap: 6px;

      &__title {
        grid-area: title;
      }
      &__desc {
        grid-area: desc;
        font-family: "Effra Light", sans-serif;
      }
    }

    &__activation {
      grid-area: activation;
      display: grid;
      grid-template-columns: 1fr auto;
      grid-column-gap: 20px;
      align-items: center;

      &__text {
        justify-self: end;
        font-family: "Effra Regular", sans-serif;
        font-size: 0.938rem;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: $triadic800;
      }

      &__switch {
        justify-self: end;
      }
    }
  }
}
</style>
