<template>
  <div>
    <div v-if="image" class="error-page__image">
      <img :src="image.src" :alt="image.alt" />
    </div>
    <div v-else class="error-page__title">
      <h1>{{ number }}</h1>
    </div>
    <div class="error-page__description">
      <p class="error-page__description__reason">{{ $t(`error.${number}`) }}</p>
      <p v-if="message" class="error-page__description__action">
        {{ message }}
      </p>
      <p>{{ $t("error.continue") }}</p>
    </div>

    <div class="error-page__button">
      <button class="btn btn-primary" data-cy="home-button" @click.prevent="onClick">
        {{ $t("button.home") }}
      </button>
    </div>
  </div>
</template>
<script>
import ErrorPageMixin from "@/mixins/ErrorPageMixin"

export default {
  name: "ErrorPageContainer",
  mixins: [ErrorPageMixin],
  props: {
    image: { type: Object, default: null },
    number: { type: String, required: true },
    message: { type: String, default: null },
  },
  methods: {
    onClick() {
      this.pushI18nRoute({ name: this.getUrlName })
    },
  },
}
</script>

<style lang="scss" scoped>
.error-page__image,
.error-page__title {
  text-align: center;
  margin-top: 5rem;
}

.error-page__description {
  text-align: center;
  margin-top: 1rem;
}

.error-page__description__reason {
  font-weight: bold;
}

.error-page__description__action {
  color: #dc3545;
}

.error-page__button {
  text-align: center;
  margin-top: 1rem;
}
</style>
