<template>
  <ErrorPageContainer number="500"></ErrorPageContainer>
</template>
<script>
import ErrorPageContainer from "../../components/errors/ErrorPageContainer"

export default {
  name: "Page500",
  components: { ErrorPageContainer },
}
</script>
