<template>
  <router-link class="apis__menu__item" :class="isItemSelected" :to="{ name: idApi }">
    <div class="apis__menu__icons">
      <span class="icon-cog icons-main"></span>
      <span class="icons-secondary" :class="logo"></span>
    </div>
    <div class="apis__menu__name">{{ $t(`home.blockAPI.${idApi}.name`) }}</div>
  </router-link>
</template>

<script>
export default {
  props: {
    logo: {
      type: String,
      required: true,
    },
    idApi: {
      type: String,
      required: true,
    },
    apiSelected: {
      type: String,
      required: true,
    },
  },
  computed: {
    isItemSelected() {
      return this.apiSelected === this.idApi ? "apis__menu__item-selected" : null
    },
  },
}
</script>

<style lang="scss" scoped>
.apis__menu__item {
  display: flex;
  padding: 25px 0 25px 15px;
  margin: 14px 3px 14px 0;
  color: rgba(31, 32, 65, 0.25);
  border-radius: 2px;
  background-color: $caarealightgray;

  .apis__menu__icons {
    .icons-main {
      position: relative;
      font-size: 1.3rem;
    }

    .icons-secondary {
      font-size: 0.9rem;
      position: relative;
      top: -14px;
      right: 4px;
    }
  }

  .apis__menu__name {
    font-size: 20px;
    font-family: "Gilroy SemiBold", sans-serif;
    letter-spacing: 0.04em;
  }
}

.apis__menu__item-selected {
  color: $bleucaarea;
}
</style>
