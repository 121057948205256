import button from "./button.json"
import home from "./views/home.json"
import footer from "./layouts/footer.json"
import apidoc from "./views/apiDoc.json"
import apikeys from "./views/apiKeys.json"
import partnerKey from "./views/partnerKey.json"
import apiKeysSecurity from "./views/apiKeysSecurity.json"
import error from "./views/error.json"
import burgermenu from "./layouts/burgermenu"
import spinner from "./spinner.json"
import breadcrumb from "./breadcrumb.json"
import caareavlib from "../../../node_modules/caarea-vlib/src/i18n/en"
import apiKeysResources from "./views/apiKeysResources.json"
import apiKeysAccess from "./views/apiKeysAccess.json"

export default {
  caareavlib,
  button,
  error,
  home,
  footer,
  apidoc,
  apikeys,
  partnerKey,
  apiKeysSecurity,
  burgermenu,
  spinner,
  breadcrumb,
  apiKeysResources,
  apiKeysAccess,
}
