<template>
  <div class="apis__menu">
    <ApiMenuItem
      v-for="api in apis"
      :key="api.id"
      :logo="api.logo"
      :id-api="api.id"
      :api-selected="apiToDisplay"
      :data-cy="`menu-${api.id}`"
    />
    <a
      class="apis__menu__item apis__menu_keys"
      :class="isItemSelected"
      @click="onApiKeysClick"
    >
      <div class="apis__menu__icons">
        <span class="icon-key"></span>
      </div>
      <div class="apis__menu__name">{{ $t(`home.apiKey`) }}</div>
    </a>
  </div>
</template>

<script>
import ApiMenuItem from "@/components/api/ApiMenuItem"

export default {
  components: { ApiMenuItem },
  data() {
    return {
      apis: [
        { id: "apisales", logo: "icon-coin-dollar" },
        { id: "apimycaarea", logo: "icon-user" },
      ],
    }
  },
  props: {
    apiToDisplay: {
      type: String,
      required: true,
    },
  },
  methods: {
    onApiKeysClick() {
      this.$router.push({ name: "api-keys" })
    },
  },
  computed: {
    isItemSelected() {
      return this.$route.name === "api-keys" ? "apis__menu__item-selected" : null
    },
  },
}
</script>

<style lang="scss" scoped>
.apis__menu {
  position: fixed;
  z-index: 1;
  padding-top: 30px;
  width: 255px;
  height: 100vh;
  color: white;
  background-color: white;

  .apis__menu_keys {
    cursor: pointer;
  }
}
.apis__menu__item {
  display: flex;
  padding: 25px 0 25px 15px;
  margin: 14px 3px 14px 0;
  color: rgba(31, 32, 65, 0.25);
  border-radius: 2px;
  background-color: $caarealightgray;

  .apis__menu__icons {
    .icons-main {
      position: relative;
      font-size: 1.3rem;
    }

    .icons-secondary {
      font-size: 0.9rem;
      position: relative;
      top: -14px;
      right: 4px;
    }
  }

  .apis__menu__name {
    font-size: 20px;
    font-family: "Gilroy SemiBold", sans-serif;
    letter-spacing: 0.04em;
  }
}

.apis__menu__item-selected {
  color: $bleucaarea;

  .apis__menu_keys {
    cursor: pointer;
  }
}
.apis__menu__item {
  display: flex;
  padding: 25px 0 25px 15px;
  margin: 14px 3px 14px 0;
  color: rgba(31, 32, 65, 0.25);
  border-radius: 2px;
  background-color: $caarealightgray;

  .apis__menu__icons {
    .icons-main {
      position: relative;
      font-size: 1.3rem;
    }

    .icons-secondary {
      font-size: 0.9rem;
      position: relative;
      top: -14px;
      right: 4px;
    }
  }

  .apis__menu__name {
    margin-left: 0.5rem;
    font-size: 20px;
    font-family: "Gilroy SemiBold", sans-serif;
    letter-spacing: 0.04em;
  }
}
.apis__menu__item:hover,
.apis__menu__item-selected {
  color: $bleucaarea;
}
.apis__menu_keys {
  position: absolute;
  bottom: 224px;
  width: 93%;
}
</style>
