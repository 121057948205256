<template>
  <div class="partnerkey">
    <ModalIdConfirmation
      v-if="showModalIdConfirmation"
      modal-name="modal-id-confirmation"
      @close="onClose"
      @validate="onValidate"
    ></ModalIdConfirmation>
    <ModalWarning
      v-if="showModalWarning"
      modal-name="modal-warning"
      :title="$t('partnerKey.not_found_error_title')"
      :reason="getPartnerKeyNotFoundError"
      @close="onModalWarningClose"
    ></ModalWarning>
    <div class="partnerkey__left-insert"></div>
    <div class="partnerkey__right">
      <h4 class="partnerkey__title">
        {{ $t("partnerKey.my_partner_key") }}
      </h4>
      <p class="partnerkey__title_key">{{ $t("partnerKey.partner_key") }}</p>
      <p class="partnerkey__id">{{ $t("partnerKey.partner_id") }}</p>
      <p class="partnerkey__key">
        <span v-if="isPartnerKeyVisible" data-cy="secret-key">
          {{ partnerKey.key }}
        </span>
        <span v-else data-cy="hide-secret-key">
          ************************************
        </span>
        <span class="icon-eye" data-cy="icon-eye" @click="onEyeIconClick"></span>
      </p>
    </div>
  </div>
</template>

<script>
import ModalIdConfirmation from "@/components/modal/ModalIdConfirmation"
import { mapGetters } from "vuex"
import ModalWarning from "@/components/modal/ModalWarning"

export default {
  name: "PartnerKey",
  components: { ModalIdConfirmation, ModalWarning },
  data() {
    return {
      showModalIdConfirmation: false,
      showModalWarning: false,
      partnerKey: null,
    }
  },
  computed: {
    ...mapGetters("partnerKey", [
      "getPartnerKeyNotFoundError",
      "isPartnerKeyVisible",
      "getTimeoutId",
    ]),
  },
  methods: {
    onEyeIconClick() {
      if (this.isPartnerKeyVisible) {
        this.$store.dispatch("partnerKey/toggleVisibility", false)
        this.partnerKey = null
        clearTimeout(this.getTimeoutId)
      } else {
        this.showModalIdConfirmation = true
      }
    },
    onClose() {
      this.showModalIdConfirmation = false
    },
    onModalWarningClose() {
      this.showModalWarning = false
    },
    onValidate(partnerKey) {
      this.showModalIdConfirmation = false
      if (this.getPartnerKeyNotFoundError) {
        this.showModalWarning = true
      } else {
        this.partnerKey = partnerKey
        this.$store.dispatch("partnerKey/toggleVisibility", true)
        // Hide partner key after 3 minutes
        let timeoutId = setTimeout(() => {
          this.$store.dispatch("partnerKey/toggleVisibility", false)
          this.partnerKey = null
        }, 180000)
        this.$store.dispatch("partnerKey/setTimeoutId", timeoutId)
      }
    },
  },
  created() {
    this.$store.dispatch("partnerKey/toggleVisibility", false)
    clearTimeout(this.getTimeoutId)
  },
}
</script>

<style scoped lang="scss">
.partnerkey {
  margin-top: 20px;
  background: #fff;
  display: grid;
  grid-template-columns: 6px auto;
  border-bottom: 1px solid #e7eaf0;
  .partnerkey__left-insert {
    background: $bleuc400;
  }

  .partnerkey__right {
    padding: 12px 44px 4px 20px;
    display: grid;
    column-gap: 20px;
    row-gap: 8px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "title title-key"
      "id key";
    h4,
    p {
      margin: 0;
    }
    .partnerkey__title {
      grid-area: title;
      font-size: 18px;
    }
    .partnerkey__title_key {
      grid-area: title-key;
      text-align: right;
      font-family: "Effra Light", sans-serif;
      font-size: 11px;
    }
    .partnerkey__id {
      grid-area: id;
      font-family: "Effra Light", sans-serif;
      font-size: 11px;
    }
    .partnerkey__key {
      grid-area: key;
      text-align: right;
      .icon-eye {
        font-weight: bold;
        padding-left: 14px;
        cursor: pointer;
      }
    }
  }
}
</style>
