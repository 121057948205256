<template>
  <div>
    <spinner v-if="isLoading" :no-message="true"></spinner>
    <div v-else>
      <div class="api-keys-access" data-cy="api_keys_access">
        <ApiKeySettingsHeader
          :api-key-id="id"
          :section-title="$t('apiKeysAccess.title')"
          :description="$t('apiKeysAccess.description')"
          :instructions="$t('apiKeysAccess.activation_instructions')"
          :is-checked="isRestrictionActivated"
          data-cy-prefix="endpoint-restriction"
          @switch-clicked="onSwitchClick()"
        ></ApiKeySettingsHeader>
        <div v-if="isRestrictionActivated" class="api-keys-access__restriction">
          <ApiKeyAccessRestrictions
            api-label="Sales"
            api-name="sales"
            :swagger-url="apiSalesSwagger"
          />
          <ApiKeyAccessRestrictions
            api-label="MyCaarea"
            api-name="my_caarea"
            :swagger-url="apiMyCaareaSwagger"
          />
        </div>
        <ActionButton
          :button-text="$t('button.save')"
          :button-class="['apikeys__save_button']"
          @click-action="onSavePermissions"
          button-data-cy="save-button"
        ></ActionButton>
      </div>
    </div>
  </div>
</template>

<script>
import ApiKeySettingsHeader from "@/components/apiKeys/utils/ApiKeySettingsHeader.vue"
import ApiKeyAccessRestrictions from "@/components/apiKeys/access/ApiKeyAccessRestrictions.vue"
import ActionButton from "@/components/button/ActionButton.vue"
import { mapGetters } from "vuex"

export default {
  name: "ApiKeyAccess",
  components: { ApiKeyAccessRestrictions, ActionButton, ApiKeySettingsHeader },
  props: {
    id: { type: [Number, String], required: true },
  },
  data() {
    return {
      isLoading: true,
      isRestrictionActivated: false,
      apiSalesSwagger: process.env["VUE_APP_API_SALES"],
      apiMyCaareaSwagger: process.env["VUE_APP_API_MY_CAAREA"],
    }
  },
  computed: {
    ...mapGetters("apiKeys", [
      "getApiKeyScopeUpdateErrors",
      "isAccessRestrictionActivated",
    ]),
  },
  methods: {
    async onSavePermissions() {
      let payload = {
        access_restriction: this.isRestrictionActivated,
      }
      await this.$store.dispatch("apiKeys/updateApiKeyPermissions", payload)
      if (this.getApiKeyScopeUpdateErrors === null) {
        await this.$store.dispatch("gui/setApiKeyUpdateFeedbackModalInfo", {
          title: this.$t("apiKeysAccess.feedback_modal.title"),
          message: this.$t("apiKeysAccess.feedback_modal.message"),
        })
        await this.$store.dispatch("gui/showApiKeyUpdateFeedbackModal")
      } else {
        await this.$store.dispatch("gui/setApiKeyUpdateWarningModalInfo", {
          title: this.$t("apiKeysAccess.warning_modal.title"),
          reason: this.getApiKeyScopeUpdateErrors,
        })
        await this.$store.dispatch("gui/showApiKeyUpdateWarningModal")
      }
    },
    onSwitchClick() {
      this.isRestrictionActivated = !this.isRestrictionActivated
    },
  },
  async created() {
    this.isLoading = true
    await this.$store.dispatch("apiKeys/retrieveApiKey", { id: this.id })
    await this.$store.dispatch("apiKeys/openPermissionsApiKeyView", this.id)
    this.isRestrictionActivated = this.isAccessRestrictionActivated(this.id)
    this.isLoading = false
  },
}
</script>

<style scoped lang="scss">
.api-keys-access__restriction {
  display: flex;
  margin-top: 1px;
  padding: 2rem;
  background-color: #fff;
}
</style>
