<template>
  <ErrorPageContainer number="403"></ErrorPageContainer>
</template>
<script>
import ErrorPageContainer from "../../components/errors/ErrorPageContainer"

export default {
  name: "Page403",
  components: { ErrorPageContainer },
}
</script>
