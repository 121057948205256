import HttpService, { HttpError } from "@/services/technical/HttpService"

const state = {
  endpoints: {},
  get_endpoints_errors: null,
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_ENDPOINTS(state, payload) {
    state.endpoints[payload.apiName] = payload.endpoints
  },
  SET_GET_ENDPOINTS_ERRORS(state, errors) {
    state.get_endpoints_errors = errors
  },
  RESET_GET_ENDPOINTS_ERRORS(state) {
    state.get_endpoints_errors = null
  },
}

// ACTIONS (camelCase)
const actions = {
  async getEndpoints({ commit }, payload) {
    commit("RESET_GET_ENDPOINTS_ERRORS")
    try {
      const openapiResponse = await HttpService.get(payload.swaggerUrl)
      commit("SET_ENDPOINTS", {
        apiName: payload.apiName,
        endpoints: Object.keys(openapiResponse["paths"]),
      })
    } catch (e) {
      console.error("failed: ", e)
      if (e instanceof HttpError) {
        if (e.status === 422) {
          commit("SET_GET_ENDPOINTS_ERRORS", e.data)
          return
        }
      }
      throw e
    }
  },
}

// GETTERS (camelCase)
const getters = {
  getEndpoints: () => state.endpoints,
  getGetEndpointsErrors: () => state.get_endpoints_errors,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
