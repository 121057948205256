<template>
  <div v-if="restrictionChecked" class="api-keys-security__restriction__body">
    <div
      v-for="(ipAddress, index) in listIpsAddresses"
      :key="index"
      class="api-keys-security__restriction__item"
      data-cy="api_key_security_ip_item"
    >
      <p class="font-size-14" :data-cy="`api_key_${index}_security_ip_item`">
        {{ ipAddress }}
      </p>
      <span
        class="icon-trash2"
        @click="onDeleteIpAddressClick(index)"
        :data-cy="`api_key_${index}_ip_remove`"
      ></span>
    </div>
    <div class="api-keys-security__restriction__add-item">
      <FormInput
        class="api-keys-security__restriction__input"
        :name="`new-ip-address-name`"
        :placeholder="$t('apiKeysSecurity.new_item_placeholder')"
        v-model="form.newIpAddress"
      ></FormInput>
      <ActionButton
        :button-class="['api-keys-security__restriction__button']"
        :button-text="$t('button.validate')"
        button-data-cy="add-ip-address-button"
        @click-action="onAddIpAddressClick"
      ></ActionButton>
    </div>
  </div>
</template>

<script>
import ActionButton from "@/components/button/ActionButton"
import { mapGetters } from "vuex"

export default {
  name: "ApiKeySecurityRestrictionsIpItems",
  props: {
    restrictionChecked: { type: Boolean, required: true },
  },
  components: { ActionButton },
  computed: {
    ...mapGetters("apiKeys", ["listIpsAddresses"]),
  },
  data() {
    return {
      form: { newIpAddress: "" },
    }
  },
  methods: {
    onDeleteIpAddressClick(index) {
      let newIpAddresses = [...this.listIpsAddresses]
      newIpAddresses.splice(index, 1)
      this.$store.dispatch("apiKeys/setApiKeyIpsInScope", newIpAddresses)
    },
    onAddIpAddressClick() {
      const newIpAddresses = [...this.listIpsAddresses, this.form.newIpAddress]
      this.$store.dispatch("apiKeys/setApiKeyIpsInScope", newIpAddresses)
      this.form.newIpAddress = ""
    },
  },
}
</script>

<style scoped lang="scss">
.api-keys-security__restriction__body {
  padding-top: 20px;
  border-left: 1px solid $bleuc50;
  height: calc(100% - 55px - 20px);
}
.api-keys-security__restriction__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $bleuc50;
  border-bottom: 1px solid $bleuc50;
  margin-top: -1px;
  margin-left: 20px;
  padding: 0.5rem;

  p {
    margin: 0;
  }

  span {
    cursor: pointer;
  }
}

.api-keys-security__restriction__item:nth-child(2n + 1) {
  background-color: $caarealightgray;
}

.api-keys-security__restriction__add-item {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0 1rem 1rem;

  .api-keys-security__restriction__button {
    margin-left: 1rem;
  }
}

.api-keys-security__restriction__input {
  width: 100%;
  margin-right: 1rem;
}
</style>
