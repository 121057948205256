<template>
  <ModalBase :show="show" :modal-name="modalName">
    <template #header>
      <div class="modal-permissions-confirmation__header">
        <h3
          class="modal-permissions-confirmation__header__title"
          v-if="confirmationModalType === 'ips'"
        >
          {{ $t("apiKeysSecurity.ip_permissions_modal_title") }}
        </h3>
        <h3
          class="modal-permissions-confirmation__header__title"
          v-else-if="confirmationModalType === 'urls'"
        >
          {{ $t("apiKeysSecurity.url_permissions_modal_title") }}
        </h3>
        <button
          type="button"
          class="modal-permissions-confirmation__header__close icon-cross"
          @click.prevent="$emit('close')"
        ></button>
      </div>
    </template>
    <template #content>
      <div class="modal-permissions-confirmation__content">
        <p class="font-size-20" v-if="confirmationModalType === 'ips'">
          {{ $t("apiKeysSecurity.ip_permissions_modal_subtitle") }}
        </p>
        <p class="font-size-20" v-else-if="confirmationModalType === 'urls'">
          {{ $t("apiKeysSecurity.url_permissions_modal_subtitle") }}
        </p>
      </div>
    </template>
    <template #footer>
      <div class="modal-permissions-confirmation__footer">
        <button
          class="btn btn-primary"
          :data-cy="`validate-${confirmationModalType}-permissions-confirmation-button`"
          @click.prevent="$emit('validate')"
        >
          {{ $t("button.validate") }}
        </button>
        <button
          class="btn btn-primary"
          :data-cy="`cancel-${confirmationModalType}-permissions-confirmation-button`"
          @click.prevent="$emit('close')"
        >
          {{ $t("button.cancel") }}
        </button>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import ModalBase from "@/components/modal/ModalBase"

export default {
  name: "ModalApiKeyPermissions",
  components: { ModalBase },
  props: {
    show: { type: Boolean, defaults: false },
    headerClass: { type: String },
    modalName: { type: String },
    confirmationModalType: { type: String },
  },
}
</script>

<style scoped lang="scss">
.modal-permissions-confirmation {
  &__header {
    display: flex;
    justify-content: space-between;

    &__title {
      align-self: center;
      flex: 1;
    }

    &__close {
      color: $bleuc400;
      transition: all 0.3s;
      cursor: pointer;
      font-size: 1.5rem;
      position: relative;
      border: none;
      background: transparent;
      align-self: center;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__input {
      margin-right: 2rem;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
  }
}
.modal-permissions-confirmation__footer {
  button {
    &:first-child {
      margin-right: 20px;
    }
  }
}
</style>
