<template>
  <div class="pb-3">
    <div class="title">
      <h3 class="font-size-40 w-100" data-cy="content-title">
        {{ getTitle }}
      </h3>
      <p v-if="getDesc" class="font-size-14 w-100" data-cy="content-desc">
        {{ getDesc }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentHeader",
  props: {
    title: { type: String, default: null },
    desc: { type: String, default: null },
  },
  computed: {
    getTitle() {
      if (this.title) {
        return this.title
      }
      return this.$router.currentRoute.meta.hasOwnProperty("contentTitle")
        ? this.$t(this.$router.currentRoute.meta.contentTitle)
        : null
    },
    getDesc() {
      if (this.desc) {
        return this.desc
      }
      return this.$router.currentRoute.meta.hasOwnProperty("contentDesc")
        ? this.$t(this.$router.currentRoute.meta.contentDesc)
        : null
    },
  },
}
</script>

<style scoped></style>
