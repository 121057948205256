<template>
  <div class="apikeys">
    <ModalFeedback
      v-if="mustApiKeyUpdateFeedbackModalBeDisplayed"
      :title="getApiKeyUpdateFeedbackModalInfo.title"
      :message="getApiKeyUpdateFeedbackModalInfo.message"
      @close="onCloseFeedbackModal()"
      data-cy="api-key-update-feedback-modal"
    ></ModalFeedback>
    <ModalWarning
      v-if="mustApiKeyUpdateWarningModalBeDisplayed"
      :title="getApiKeyUpdateWarningModalInfo.title"
      :reason="getApiKeyUpdateWarningModalInfo.reason"
      @close="onCloseWarningModal()"
      data-cy="api-key-update-warning-modal"
    ></ModalWarning>
    <spinner v-if="isLoading" :no-message="true"></spinner>
    <div v-else>
      <div class="apikeys__header">
        <h3>{{ $t("apikeys.main_title") }}</h3>
        <FormRowSelect
          v-if="isApiKeysHome && isInternalAdmin"
          name="partners"
          :label="$t('apikeys.partner_account')"
          :label-select-attr="$t('apikeys.select_partner')"
          :label-class="[
            'col-sm-4',
            'text-center',
            'align-self-center',
            'apikey__input-label',
          ]"
          :select-options="listPartnersForSelectOptions"
          :selected-option.sync="partnerId"
          @update:selected-option="onUpdatePartner($event)"
        ></FormRowSelect>
      </div>
      <div>
        <spinner v-if="isPartnerChangeInProgress" :no-message="true"></spinner>
        <transition v-else name="fade" mode="out-in">
          <router-view class="" />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ModalFeedback from "@/components/modal/ModalFeedback"
import ModalWarning from "@/components/modal/ModalWarning"

export default {
  name: "ApiKeysLayout",
  components: { ModalFeedback, ModalWarning },
  computed: {
    ...mapGetters("partner", ["listPartnersForSelectOptions", "listPartners"]),
    ...mapGetters("auth", ["isInternalAdmin"]),
    ...mapGetters("gui", [
      "mustApiKeyUpdateFeedbackModalBeDisplayed",
      "getApiKeyUpdateFeedbackModalInfo",
      "mustApiKeyUpdateWarningModalBeDisplayed",
      "getApiKeyUpdateWarningModalInfo",
    ]),
    isApiKeysHome() {
      return this.$route.name === "api-keys-list"
    },
  },
  data() {
    return {
      isLoading: false,
      isPartnerChangeInProgress: false,
      partnerId: null,
    }
  },
  methods: {
    async onUpdatePartner(e) {
      this.isPartnerChangeInProgress = true
      this.partnerId = e
      await this.$store.dispatch("partner/setSelectedPartner", this.partnerId)
      await this.$store.dispatch("apiKeys/retrieveApiKeys", this.partnerId)
      this.$store.dispatch("partnerKey/toggleVisibility", false)
      clearTimeout(this.getTimeoutId)
      this.isPartnerChangeInProgress = false
    },
    onCloseFeedbackModal() {
      this.$store.dispatch("gui/hideApiKeyUpdateFeedbackModal")
      this.$store.dispatch("gui/resetApiKeyUpdateFeedbackModalInfo")
    },
    onCloseWarningModal() {
      this.$store.dispatch("gui/hideApiKeyUpdateWarningModal")
      this.$store.dispatch("gui/resetApiKeyUpdateWarningModalInfo")
    },
  },
  async created() {
    this.isLoading = true
    if (this.isApiKeysHome) {
      if (this.isInternalAdmin) {
        await this.$store.dispatch("partner/initPartners")
        this.partnerId = this.listPartners[0].id.toString()
        await this.$store.dispatch("apiKeys/retrieveApiKeys", this.partnerId)
        await this.$store.dispatch("partner/setSelectedPartner", this.partnerId)
      } else {
        await this.$store.dispatch("apiKeys/retrieveApiKeys")
      }
    }
    this.isLoading = false
  },
}
</script>

<style scoped lang="scss">
.apikeys {
  margin: 24px 24px 0 279px;
  width: 100%;
  .apikeys__header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 25px;
    h3 {
      margin: 0;
      color: $caareadark;
      font-size: 22px;
      line-height: 38px;
      letter-spacing: 0.09em;
    }
  }
}
.spinner {
  position: absolute;
  top: 50%;
  right: 50%;
}
</style>
