<template>
  <div class="btn-group">
    <ul>
      <li
        @click="toggleMenu()"
        class="dropdown-toggle"
        v-if="selectedOption.name !== undefined"
      >
        {{ selectedOption.name }}
        <span class="caret"></span>
      </li>

      <li
        @click="toggleMenu()"
        class="dropdown-toggle dropdown-toggle-placeholder"
        v-if="selectedOption.name === undefined"
      >
        {{ placeholderText }}
        <span class="caret"></span>
      </li>
    </ul>

    <ul class="dropdown-menu" v-if="showMenu">
      <li v-for="(option, idx) in options" :key="idx">
        <button
          class="dropdown-menu__button"
          :disabled="option.name === selectedOption.name"
          :data-cy="`change-language-btn-${selectedOption.name}`"
          @click="updateOption(option)"
        >
          {{ option.name }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedOption: {
        name: "",
      },
      showMenu: false,
      placeholderText: "Please select an item",
    }
  },
  props: {
    options: {
      type: [Array, Object],
    },
    selected: {},
    placeholder: [String],
    closeOnOutsideClick: {
      type: [Boolean],
      default: true,
    },
  },
  mounted() {
    this.selectedOption = this.selected
    if (this.placeholder) {
      this.placeholderText = this.placeholder
    }
    if (this.closeOnOutsideClick) {
      document.addEventListener("click", this.clickHandler)
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickHandler)
  },
  methods: {
    updateOption(option) {
      this.selectedOption = option
      this.showMenu = false
      this.$emit("updateOption", this.selectedOption)
    },
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
    clickHandler(event) {
      const { target } = event
      const { $el } = this
      if (!$el.contains(target)) {
        this.showMenu = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-group {
  min-width: 160px;
  height: 40px;
  position: relative;
  vertical-align: middle;
}
.btn-group a:hover {
  text-decoration: none;
}
.dropdown-toggle {
  color: #636b6f;
  min-width: 160px;
  padding: 5px 20px 5px 10px;
  text-transform: none;
  font-weight: 300;
  margin-bottom: 7px;
  border: 0;
  background-image: linear-gradient(#009688, #009688), linear-gradient(#d2d2d2, #d2d2d2);
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: transparent;
  transition: background 0s ease-out;
  float: none;
  box-shadow: none;
  border-radius: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.dropdown-toggle:hover {
  cursor: pointer;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}
.dropdown-menu__button {
  background-color: white;
  padding: 10px 30px;
  display: block;
  clear: both;
  font-weight: normal;
  line-height: 1.6;
  color: $bleucaarea;
  white-space: nowrap;
  text-transform: uppercase;
  border: none;

  &:hover:not([disabled]) {
    background: #efefef;
    cursor: pointer;
  }

  &[disabled] {
    color: #8b8b8c;
  }
}

.dropdown-menu > li {
  overflow: hidden;
  width: 100%;
  position: relative;
  margin: 0;
}
.caret {
  width: 0;
  position: absolute;
  top: 19px;
  height: 0;
  margin-left: -24px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  right: 10px;
}
li {
  list-style: none;
}
</style>
