<template>
  <header data-cy="top_bar" class="header">
    <div>
      <div class="header-common">
        <div class="header-common__logo">
          <a href="/">
            <img
              src="https://res.cloudinary.com/caarea/image/upload/v1617955697/cs/assets/logo-header_ef6uoh.png"
              alt="Caarea API Portal"
            />
            <p>{{ $t("home.logoText") }}</p>
          </a>
        </div>
        <div
          class="slide"
          :class="{
            'slide-start': expanded,
          }"
        >
          <div class="header-common__user-lang-menu">
            <div class="header-common__user-lang-menu__user">
              <div v-if="isLoggedIn" data-cy="username">
                <span class="fullname">{{ fullname }}</span>
              </div>
              <div>
                <img :src="avatarUrl" :alt="fullname" :title="fullname" />
              </div>
            </div>
            <div class="header-common__user-lang-menu__lang lang-dropdown">
              <dropdown
                :options="languages"
                :selected="currentLang"
                @updateOption="onChangeCurrentLanguage"
                data-cy="languageDropdownMenuButton"
              ></dropdown>
            </div>
            <div class="header-common__user-lang-menu__menu" v-if="areCookiesAccepted">
              <a
                @click="toggleVisibility()"
                id="open-sidenav"
                class="burger-menu"
                href="#"
                data-cy="burger_icon"
              >
                <div
                  class="icon-menu rotate"
                  :class="{
                    'rotate-start': expanded,
                  }"
                  aria-hidden="true"
                ></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$router.currentRoute.name !== 'home'">
      <div class="bg-header"></div>
      <div class="breadcrumb-header">
        <breadcrumb :key="$router.currentRoute.meta.breadcrumb"></breadcrumb>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex"
import Breadcrumb from "@/layouts/components/Breadcrumb"
import i18n from "@/i18n/index"
import SsoService from "../../services/technical/SsoService"
import dropdown from "@/layouts/components/Dropdown"

export default {
  name: "TopBar",
  components: { Breadcrumb, dropdown },
  props: ["expanded"],
  computed: {
    ...mapGetters("auth", ["getCurrentUser", "areCookiesAccepted"]),
    ...mapGetters("config", ["getCurrentLang"]),
    currentLang() {
      return { name: this.getCurrentLang }
    },
    languages() {
      let languages = []
      for (const lang of Object.keys(i18n.messages)) {
        if (lang === this.getCurrentLang) {
          languages.unshift({ name: lang })
        } else {
          languages.push({ name: lang })
        }
      }
      return languages
    },
    fullname() {
      if (this.getCurrentUser.first_name || this.getCurrentUser.last_name) {
        return this.getCurrentUser.first_name + " " + this.getCurrentUser.last_name
      }
      return this.getCurrentUser.username
    },
    avatarUrl() {
      let fullname = this.fullname
      if (!this.getCurrentUser.first_name && !this.getCurrentUser.last_name) {
        fullname = fullname.substring(0, fullname.indexOf("@"))
        fullname = fullname.replaceAll(/[-, .]/gm, " ")
      }
      return (
        "https://eu.ui-avatars.com/api/?rounded=true" +
        "&size=32&bold=true&color=ffffff&background=1c2b4e" +
        "&name=" +
        encodeURIComponent(fullname)
      )
    },
    isLoggedIn() {
      return SsoService.isAuthenticated()
    },
  },
  methods: {
    toggleVisibility() {
      this.$emit("toggle-menu-visibility")
    },
    onChangeCurrentLanguage(newLang) {
      if (newLang.name !== this.currentLang.name) {
        this.$store.dispatch("config/setCurrentLang", newLang.name)
        const to = this.$router.resolve({ params: { lang: newLang.name } })
        this.$router.push(to.location)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.rotate {
  transition: all 0.3s;
}
.rotate-start {
  transform: rotate(90deg);
  opacity: 0;
}
.slide {
  transition: all 0.3s;
}
.slide-start {
  transform: translateX(-100%);
}

div.username {
  line-height: 0.9rem;
}

.fullname {
  font-family: "Effra Bold", sans-serif;
  font-size: 0.75rem;
  line-height: 14px;
  letter-spacing: 0.09rem;
}

.header-common__logo {
  margin-top: 10px;
  p {
    color: $bleucaarea;
    text-align: center;
    margin: 0;
    font-family: "Effra Regular", sans-serif;
    font-size: 1.125rem;
    line-height: 22px;
    letter-spacing: 0.09rem;
  }
}
</style>
