<template>
  <div>
    <ModalApiKeyPermissions
      :show="isConfirmationModalOpen"
      :modal-name="`api-key-${apiKeyId}-permission-remove-modal`"
      :confirmation-modal-type="restrictionType"
      @close="onModalClose"
      @validate="onValidateClick"
    ></ModalApiKeyPermissions>
    <div class="api-keys-security__restriction__header">
      <label class="switch">
        <input
          :id="`${restrictionType}-checkbox`"
          type="checkbox"
          :checked="restrictionChecked"
          @change="onSwitchClick"
          :data-cy="`${restrictionType}-checkbox`"
        />
        <span class="slider round"></span>
      </label>
      <label :for="`${restrictionType}-checkbox`" class="font-size-16">
        {{ $t(`apiKeysSecurity.${restrictionType}_restriction`) }}
      </label>
    </div>
    <ApiKeySecurityRestrictionsIpItems
      v-if="restrictionType === 'ips'"
      :restriction-checked="restrictionChecked"
    ></ApiKeySecurityRestrictionsIpItems>
    <ApiKeySecurityRestrictionsUrlItems
      v-else-if="restrictionType === 'urls'"
      :restriction-checked="restrictionChecked"
    ></ApiKeySecurityRestrictionsUrlItems>
  </div>
</template>

<script>
import ModalApiKeyPermissions from "@/components/modal/ModalApiKeyPermissions"
import ApiKeySecurityRestrictionsIpItems from "@/components/apiKeys/security/ApiKeySecurityRestrictionsIpItems"
import ApiKeySecurityRestrictionsUrlItems from "@/components/apiKeys/security/ApiKeySecurityRestrictionsUrlItems"
import { mapGetters } from "vuex"

export default {
  name: "ApiKeySecurityRestrictions",
  computed: {
    ...mapGetters("apiKeys", [
      "isIpsRestrictionActivated",
      "isUrlsRestrictionActivated",
    ]),
  },
  components: {
    ModalApiKeyPermissions,
    ApiKeySecurityRestrictionsIpItems,
    ApiKeySecurityRestrictionsUrlItems,
  },
  props: {
    apiKeyId: { type: [Number, String], required: true },
    restrictionType: { type: String, required: true },
  },
  data() {
    return {
      restrictionChecked: false,
      isConfirmationModalOpen: false,
    }
  },
  methods: {
    isIpsRestriction() {
      return this.restrictionType === "ips"
    },
    isUrlsRestriction() {
      return this.restrictionType === "urls"
    },
    onSwitchClick() {
      if (this.isIpsRestriction() && this.isIpsRestrictionActivated) {
        this.isConfirmationModalOpen = true
      } else if (this.isUrlsRestriction() && this.isUrlsRestrictionActivated) {
        this.isConfirmationModalOpen = true
      } else {
        this.restrictionChecked = !this.restrictionChecked
      }
    },
    async onValidateClick() {
      if (this.isIpsRestriction()) {
        await this.$store.dispatch("apiKeys/setApiKeyIpsInScope", [])
      } else if (this.isUrlsRestriction()) {
        await this.$store.dispatch("apiKeys/setApiKeyUrlsInScope", [])
      }
      await this.$store.dispatch("apiKeys/updateApiKeyPermissions")
      this.isConfirmationModalOpen = false
    },
    onModalClose() {
      const checkboxId = this.restrictionType + "-checkbox"
      document.getElementById(checkboxId).checked =
        !document.getElementById(checkboxId).checked
      this.isConfirmationModalOpen = false
    },
  },
  created() {
    if (this.isIpsRestriction()) {
      this.restrictionChecked = this.isIpsRestrictionActivated
    } else if (this.isUrlsRestriction()) {
      this.restrictionChecked = this.isUrlsRestrictionActivated
    }
  },
}
</script>

<style scoped lang="scss">
.api-keys-security__restriction__header {
  display: flex;
  align-items: center;
  border-left: 6px solid $secondary500;
  border-top: 1px solid $bleuc50;
  border-bottom: 1px solid $bleuc50;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  label {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  p {
    margin: 0;
  }
}
</style>
