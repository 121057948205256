<template>
  <ModalBase :show="true" :modal-name="modalName">
    <template #header>
      <div class="modal-id-confirmation__header">
        <h3
          class="modal-id-confirmation__header__title"
          data-cy="modal-id-confirmation-title"
        >
          {{ $t("partnerKey.modal_id_confirmation.title") }}
        </h3>
        <button
          type="button"
          class="modal-id-confirmation__header__close icon-cross"
          @click="onClose"
        ></button>
      </div>
    </template>
    <template #content>
      <div class="modal-id-confirmation__content">
        <p class="font-size-20" data-cy="modal-id-confirmation-instructions">
          {{ $t("partnerKey.modal_id_confirmation.instructions") }}
        </p>
        <FormInput
          v-model="form.password"
          class="modal-id-confirmation__content__input"
          name="password"
          type="password"
          :placeholder="$t('partnerKey.modal_id_confirmation.password')"
          @keyboard-enter="onValidateClick"
          :errors="getError"
        />
      </div>
    </template>
    <template #footer>
      <div class="modal-id-confirmation__footer">
        <button
          class="btn btn-primary"
          data-cy="validate-id-confirmation-button"
          :disabled="form.password === ''"
          @click.prevent="onValidateClick"
        >
          {{ $t("button.validate") }}
        </button>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import ModalBase from "@/components/modal/ModalBase"
import { mapGetters } from "vuex"

export default {
  name: "ModalIdConfirmation",
  components: { ModalBase },
  props: {
    modalName: { type: String },
  },
  data() {
    return {
      form: { password: "" },
    }
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("partnerKey", ["getPartnerKeyError"]),
    ...mapGetters("partner", ["hasSelectedPartner", "getSelectedPartner"]),
    getError() {
      if (this.getPartnerKeyError) {
        return this.getPartnerKeyError.hasOwnProperty("error")
          ? { password: this.getPartnerKeyError["error"] }
          : this.getPartnerKeyError
      }
      return {}
    },
  },
  methods: {
    async onValidateClick() {
      let username = this.getCurrentUser.username
      let payload = {
        username: username,
        password: this.form.password,
      }
      if (this.hasSelectedPartner) {
        payload["partner_id"] = this.getSelectedPartner
      }
      const partnerKey = await this.$store.dispatch(
        "partnerKey/retrievePartnerKey",
        payload
      )
      if (!this.getPartnerKeyError) {
        this.$emit("validate", partnerKey)
      }
    },
    onClose() {
      this.$store.dispatch("partnerKey/resetPartnerKeyError")
      this.$emit("close")
    },
  },
}
</script>

<style scoped lang="scss">
.modal-id-confirmation {
  &__header {
    display: flex;
    justify-content: space-between;

    &__title {
      align-self: center;
      flex: 1;
    }

    &__close {
      color: $bleuc400;
      transition: all 0.3s;
      cursor: pointer;
      font-size: 1.5rem;
      position: relative;
      border: none;
      background: transparent;
      align-self: center;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__input {
      margin-right: 2rem;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
  }
}
</style>
