<template>
  <router-link
    class="api-block"
    :to="{ name: disabledRoute.includes(route) ? '' : route }"
  >
    <header>
      <div class="icons">
        <span class="icon-cog icons-main"></span>
        <span class="icons-secondary" :class="logo"></span>
      </div>
      <h3>{{ title }}</h3>
    </header>
    <p>{{ description }}</p>
  </router-link>
</template>

<script>
export default {
  props: {
    route: { type: String, required: true },
    logo: { type: String, required: true },
    title: { type: String, required: true },
    description: { type: String, required: true },
  },
  data() {
    return {
      disabledRoute: ["apiclaims", "apimonitoring"],
    }
  },
}
</script>
<style scoped lang="scss">
.api-block {
  background-color: $bleucaarea;
  color: $caarealightgray;
  padding: 1.4rem;
  text-align: center;
  height: 220px;

  .icons {
    padding-top: 1.5rem;

    .icons-main {
      position: relative;
      font-size: 1.3rem;
    }

    .icons-secondary {
      font-size: 0.9rem;
      position: relative;
      top: -14px;
      right: 4px;
    }
  }

  h3 {
    font-family: "Gilroy SemiBold", sans-serif;
    font-size: 24px;
    margin-top: 0.2rem;
    margin-bottom: 2rem;
  }

  p {
    font-family: "Effra", sans-serif;
    font-size: 18px;
    text-align: left;
  }
}
</style>
