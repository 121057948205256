<template>
  <div class="apis">
    <ApiMenu :api-to-display="apiSelected" />
    <transition name="fade" mode="out-in">
      <router-view class="" />
    </transition>
  </div>
</template>

<script>
import ApiMenu from "@/components/api/ApiMenu"

export default {
  name: "ApisLayout",
  components: { ApiMenu },
  data() {
    return {
      apiSelected: this.$router.currentRoute.meta.apiId || this.$route.name,
    }
  },
}
</script>
<style lang="scss" scoped>
.apis {
  background-color: $caarealightgray;
  display: flex;
  position: relative;
  margin-top: 174px;
}
</style>
